
@font-face {
	font-family: popins-footer;
	src: url(../fonts/Poppins-Light.ttf);
}
.max-width-wrap {
  display: flex;
  justify-content: center;
  }
.max-width {
  width: 100vw;
  max-width: 2060px;

}
ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  background: #0d77ee;
  font-family: popins-footer;
  position: relative;
}


.footer-row {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  /* border: 2px solid; */
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta {
  margin-top: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
}
.single-cta i {
  color: #f0f0f0;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #ffff;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #ffff;
  line-height: 28px;
}
a {
  text-decoration: none;
}
.footer-social-icon {
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
  margin-bottom: 30px;

}
/* .ftr-icons-a{
  border: 2px solid red;
} */

.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.footer-widget {
  margin-bottom: 40px;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 35px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #f0f0f0;
}
.footer-widget ul li {
  display: inline-block;
  /* float: left; */
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #f0f0f0;
  
  transition: 0.2s;
  padding: 10px;
  /* border-bottom: 1px solid white; */
  text-decoration: none;
}
.footer-widget ul li a {
  color: #ffff;
  text-transform: capitalize;
}
.subscribe-form {
  width:220px;
  /* padding: -20px; */
  margin-left:-20px;
  /* border: 2px solid ; */
  /* position: relative; */
  /* overflow: hidden; */
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: white;
  border: 1px solid lightblue;
  color: black;
}
.subscribe-form button {
  /* position: absolute; */
  /* right: 0; */
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  /* top: 0; */
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #006894;
  padding: 5px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: white;
}
.copyright-text p a {
  color: white;
  text-shadow:  	2px 2px  7px rgba(0, 0, 0, 0.411)  ;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: rgba(0, 0, 0, 0.507);
  text-decoration: none;
}
.footer-menu li a {
  font-size: 14px;
  color: #f0f0f0;
}
.download-app-image {
  width: 200px;
}