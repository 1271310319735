.u-section-1 .u-sheet-1 {
  min-height: 561px;
}

.u-section-1 .u-repeater-1 {
  margin-top: 60px;
  margin-bottom: 60px;
  min-height: 441px;
  grid-template-columns: calc(33.3333% - 15px) calc(33.3333% - 15px) calc(33.3333% - 15px);
  height: auto;
  grid-gap: 22px;
}

.u-section-1 .u-repeater-item-1 {
  background-image: none;
}

.u-section-1 .u-container-layout-1 {
  padding: 30px 20px;
}

.u-section-1 .u-text-1 {
  margin-top: 0;
  margin-bottom: 0;
}

.u-section-1 .u-image-1 {
  height: 222px;
  margin-top: 17px;
  margin-bottom: 0;
}

.u-section-1 .u-text-2 {
  margin-top: 20px;
  margin-bottom: 0;
}

.u-section-1 .u-btn-1 {
  background-image: none;
  border-style: none none solid;
  margin: 17px auto 0 0;
  padding: 0;
}

.u-section-1 .u-container-layout-2 {
  padding: 30px 20px;
}

.u-section-1 .u-text-3 {
  margin-top: 0;
  margin-bottom: 0;
}

.u-section-1 .u-image-2 {
  height: 222px;
  margin-top: 17px;
  margin-bottom: 0;
}

.u-section-1 .u-text-4 {
  margin-top: 20px;
  margin-bottom: 0;
}

.u-section-1 .u-btn-2 {
  background-image: none;
  border-style: none none solid;
  margin: 17px auto 0 0;
  padding: 0;
}

.u-section-1 .u-container-layout-3 {
  padding: 30px 20px;
}

.u-section-1 .u-text-5 {
  margin-top: 0;
  margin-bottom: 0;
}

.u-section-1 .u-image-3 {
  height: 222px;
  margin-top: 17px;
  margin-bottom: 0;
}

.u-section-1 .u-text-6 {
  margin-top: 20px;
  margin-bottom: 0;
}

.u-section-1 .u-btn-3 {
  background-image: none;
  border-style: none none solid;
  margin: 17px auto 0 0;
  padding: 0;
}

@media (max-width: 1199px) {
  .u-section-1 .u-sheet-1 {
    min-height: 484px;
  }

  .u-section-1 .u-repeater-1 {
    min-height: 364px;
    grid-template-columns: repeat(3, calc(33.333333333333336% - 15px));
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-sheet-1 {
    min-height: 956px;
  }

  .u-section-1 .u-repeater-1 {
    min-height: 836px;
    grid-template-columns: repeat(2, calc(50% - 11.25px));
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-repeater-1 {
    grid-template-columns: 100%;
  }

  .u-section-1 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-1 .u-image-1 {
    height: 278px;
  }

  .u-section-1 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-1 .u-image-2 {
    height: 278px;
  }

  .u-section-1 .u-container-layout-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-1 .u-image-3 {
    height: 278px;
  }
}