@font-face {
  font-family: light-popins;
  src: url("../fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: poppinsa-light;
  src: url("../fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: pop-B;
  src: url("../fonts/Poppins-ExtraBold.ttf");
}
.options-real-wrapp {
    background-color: #f0f0f0;
}
.options-head {
    max-width: 1350px;
  text-align: center;
  padding: 40px 20px 10px 20px; 
  margin: 0px auto;
}
.options-head h1 {
  font-family: pop-B;
}
.options-head p {
  margin: 0px auto;
  padding: 2px;
  padding-bottom: 10px;
  max-width: 1000px;
  /* margin: 0px auto; */
  font-family: light-popins;
  width: fit-content;
  border-bottom: 3px solid #0d77ee;
}
.options-wrap {
  /* padding: 0px 0px 0px 40px; */
  width: 100vw;
  display: flex;
  justify-content: center;
  max-width: 2060px;
}
.options-main-wrap {
  display: flex;

}
.options-main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  padding-top:5px !important;
  max-width: 1350px !important;
  justify-content: space-around;
}
.option-box {

  /* border: 3px solid #0d77ee; */
  border-radius: 20px;
  transition: 0.3s;
  padding: 20px;
  cursor: pointer;
  width: 300px;
  margin: 30px 2px;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */

}

.option-box:hover {

  color:white; 
  background-color: #0d77ee;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.option-box:hover .terara  {
  
    color: white;
  }
.opt-box-head {
  margin-bottom: 10px; 
  display: flex;
  align-items: center;
}
.opt-box-head h4 {
  padding-left: 10px;
}

.option-box:hover .Options-box-icon {
  /* fill: ; */
  background-color: #212529;
  transition: 0.3s;
}

.Options-box-icon {
  background-color: #0d77ee;

  color: white;
  fill: white;
  padding: 6px;
  border-radius: 50px;
}
.option-box h4 {
  font-family: light-popins;
  font-size: 25px;
  margin: 15px 0px;
  margin-bottom: 10px;
}
.option-box p {
  font-family: poppinsa-light;
  margin: 0px 0px 10px 0px !important;
}
.option-box button {
  color: #0d77ee;
  border: none;
  padding: 0px;
  background-color: transparent;
  font-family: poppinsa-light;
}
.option-box button:hover {
  color: gray;
  transition: 0.2s;
}

@media (min-width:778px)and (max-width:2560px) {
.options-main {
padding: 2rem 6rem;
}
}


@media  (min-width:1104px) and (max-width:2560px)  {
  .lef {
    text-align: left;
    
  }
  .cen {
    text-align:center;

  }
  .cen .opt-box-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  }
  .rgt {
    text-align: right;

  }
  .rgt .opt-box-head {
display: flex;
justify-content: right;
    /* text-align: right !important; */
  }
  .topinga {
    margin-top: 70px;
  }
}