@font-face {
    font-family: pop;
    src: url("../fonts/Poppins-SemiBold.ttf");
}

.bottom-reg {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin: 20px;
}
.bottome-reg-left {
    max-width: 800px;
}
.bottom-reg-box:hover {
    transition: 0.2s;
    background-color: #0c6bd8;
}
.bottome-reg-left h2{
    font-family: pop;
}
.bottom-reg-box {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    background-color: #0d77ee;
    color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px ;
    height: 100px ;
}
@media (max-width:888px) {
    
    .fbform-main h1{
    font-size: 30px !important;
    
    }
    .bottom-reg-box{
        width: 90vw;
        height: 60px !important;
    }
    .bottom-reg-wrap p{
        font-size: 14px;
    }
}
