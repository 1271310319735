@font-face {
  font-family: poppinsa-gift;
  src: url(../fonts/Poppins-Medium.ttf);
}
.loader-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 86vh;
}
.main-circloader {
  width: 100px;
  height  : 100px;
}
body {
  margin: 0px;
  font-family: poppinsa-gift;
}

.cards-wrap {
  display: flex;
  justify-content: center;
  text-align: center;
  height: auto;
}
.cards-main {
  width: 95%;
  padding: 4px;
  display: flex;
  padding-top: 0px;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  /* border:  1px solid red; */
}
.prod-box {
  margin: 10px 10px;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 270px;
}
.box-img-wrap {
  padding: 15px;
  height: 200px;
  display: flex;
  justify-content: center;
  object-fit: contain;
  align-items: center;
  width: 200px;
}

.prod-img {
  margin: 0px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.box-text-wrap {
  /* background-color: orange; */
  color: black;
  width: 100%;
  /* height:30px; */
  justify-content: center;
  align-items: center;
}
.prod-title-text {
  margin: 0px;
  /* margin-bottom: 7px; */
  padding: 0px 0px 14px 0px;
  color: rgba(0, 0, 0, 0.651);
}
.card-down {
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 0px 7px;
}
.card-down-icon {
  color: red;
  fill: red;
}
.card-down p {
  color: red;
  margin: 0px;
}
.buy-btn-s {
  
  background-color: #0d77ee;
  border: none;
  color: white;
  margin: 0px;
  padding: 3px;
  font-family: poppinsa-gift;
  width: 50%;
  margin: 2px;
  margin-top: 0px;
  margin-bottom: 10px;
  border-radius: 4px;
  /*  */
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buy-btn-s:hover {
  background-color: #0c60c0;
}

@media (min-width: 10px) and (max-width: 425px) {
  .prod-box {
    margin: 5px;
    background-color: transparent;
    /* border-radius: 20px; */
    cursor: pointer;
    height: 218px;
  }
  .box-img-wrap {
    padding: 15px;
    /* padding-top: 0px; */
    height: 150px;
    display: flex;
    justify-content: center;
    object-fit: contain;
    align-items: center;
    width: 135px;
    padding: 10px 10px;
  }
  .prod-title-text {
    margin: 0px;
    font-size: 15px;
    padding: 0px 0px 14px 0px;
  }
  .buy-btn-s {
    font-size: 11px;
    padding-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 360px) and (max-width: 375px) {
  
/* .cards-main {
  width: 95%;
  padding: 4px;
  display: flex;
  padding-top: 0px;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}
 */

 .prod-box {
  margin: 5px;
  background-color: transparent;
  /* border-radius: 10px; */
  cursor: pointer;
  
  height: 220px;
}
  .box-img-wrap {
    padding: 15px;
    /* padding-top: 0px; */
    height: 170px;
    display: flex;
    justify-content: center;
    object-fit: contain;
    align-items: center;
    width: 150px;
  }
  
  .box-img-wrap {
  
    height: 150px;
  }

}


@media (min-width: 376px) and (max-width: 425px) {
  
  .cards-main {
    width: 100%;
    padding: 4px;
    display: flex;
    /* padding-top: 0px; */
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
  }
  
  
   .prod-box {
    margin: 15px;
    /* margin: 5px; */
    background-color: transparent;
    cursor: pointer;
    
    height: 220px;
  }
    .box-img-wrap {
      padding: 15px;
      /* padding-top: 0px; */
      height: 170px;
      display: flex;
      justify-content: center;
      object-fit: contain;
      align-items: center;
      width: 150px;
    }
    
    .box-img-wrap {
    
      height: 150px;
    }
  
  }
  