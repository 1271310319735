@font-face {
  font-family: poppBold;
  src: url("../fonts/Poppins-Bold.ttf");
}
@font-face {
  font-family: poppLight;
  src: url("../fonts/Poppins-Light.ttf");
}

.freights {
  margin: 0px auto;
  max-width: 2060px;
  margin-top: 40px;
  background-color: #f0f0f0;
  padding: 50px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.Frgbox1 {
  width: 400px;
}
.Frgbox2 {
  margin: 0px 0px;
  width: 250px;
}
.Frgbox3 {
  width: 250px;
}
.Frgbox1 h3 {
  font-family: poppBold;
  width: 400px;
  margin-top: 0px;
  margin-bottom: 5px;
  line-height: 38px;
}
.pb5 {
  padding-top: 10px;
  /* padding-bottom: 10px; */
}
.ligt-p {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  font-family: poppLight;
  font-size: 14px;
}
.clrBrk {
  color: #0d65c9;
}
.Frgbox1 h5 {
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: poppBold;
  /* font-size: 13px; */
}

.frg-img {
  /* border: 2px solid red; */
  width: 100%;
  /* height: 150px ; */
}
.frg-icon {
    cursor: pointer;
    color: #f0f0f0;
    background-color: #0d65c9;
    color: white !important;
    fill: white !important;
    font-size: 30px;
    border-radius: 40px;
    padding: 4px;
    transition: 0.2s;
    color: white;
}
.frg-icon:hover {
    background-color: #074995;
}
@media (max-width: 425px) {
  .frg-icon {display: none;
  }
  .Frgbox1 {
    width: 100%;
  }
  .Frgbox2 {
    width: 100%;
  }
  .Frgbox3 {
    width: 100%;
  }
  .Frgbox1 h3 {
    font-family: poppBold;
    width: 400px;
    margin-top: 0px;
    margin-bottom: 5px;
    line-height: 40px;
    font-size: 33px;
  }
  .pb5 {
    /* padding-top: 5px; */
    /* padding-bottom: 10px; */
  }
  .ligt-p {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    font-family: poppLight;
    font-size: 14px;
  }
  .clrBrk {
    color: #0d65c9;
  }
  .Frgbox1 h5 {
    margin-top: 0px;
    margin-bottom: 5px;
    font-family: poppBold;
    /* font-size: 13px; */
  }

  .frg-img {
    /* border: 2px solid red; */
    width: 100%;
    /* height: 150px ; */
  }
  .frg-icon {
    color: #f0f0f0;
    background-color: #0d65c9;
    color: white !important;
    fill: white !important;
    font-size: 30px;
    border-radius: 40px;
    padding: 4px;
    color: white;
  }
}
