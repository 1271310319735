body {
  background-color: #f0f0f0;
}
.prod-wrap {
  display: flex;
  background-color: #f0f0f0;
  width: 100vw;
  /* height: 100vh; */
  flex-wrap: wrap;
}
.left-of-selected-prod {
  width: 100%;
}
.right-of-selected-prod {
  width: 100%;
  display: flex;
  justify-content: center;
  /* width: auto; */
}
.giftOrderBtn {
  min-width: 140px;}
.container-prod {
  margin-top: 30px;
  width: 100%;
  max-width: 900px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
h5 {
  text-transform: uppercase;
  margin: 0;
  font-size: 14px;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.medias-wrap {
  padding: 30px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.product-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background: linear-gradient(to bottom, #6bffc8 0%, #42c8cb 100%); */
  background-color: #f0f0f0;
  border-radius: 20px 20px 0 0;
  padding: 0px 0px 10px 0px;
  width: 100%;
  margin: auto;
}
.product-pic {
  max-width: 300px;
  position: relative;
  left: 0;
  margin: 40px 0px 50px 0px;
  filter: drop-shadow(-6px 40px 23px rgba(0, 0, 0, 0.24));
}
.product-icon {
  margin-top: -35px;
  display: flex;
  justify-content: center;
}
.product-details {
  padding: 60px 40px 0px 40px;
  background-color: white;
  background-color: #f0f0f0;

  border-radius: 0 0 20px 20px;
}
.product-details .title {
  text-transform: uppercase;
  margin: 0;
}
.product-details .colorCat {
  text-transform: uppercase;
  font-style: italic;
  color: #bbb;
  font-weight: 700;
  font-size: 14px;
}
.product-details .price {
  font-weight: 700;
  margin-top: 5px;
  font-size: 18px;
}
.product-details .price .current {
  color: #fe6168;
  margin-left: 6px;
}
.product-details .before {
  text-decoration: line-through;
}
.product-details header {
  margin-bottom: 50px;
  position: relative;
}
.product-details article > h5 {
  margin: 0;
}
.product-details article > p {
  color: #bbb;
  margin: 0.5em 0;
  font-size: 14px;
  line-height: 1.6;
}
.product-details .controls {
  margin: 1em 0;
}

.product-details .controls > .codespan-div {
  flex: 1;
}
.product-details .controls .option {
  margin-top: 12px;
  display: inline-block;
  position: relative;
}
.product-details .controls .option:hover {
  color: #444;
}
.product-details .controls .option::before {
  content: "";
  position: absolute;
  border-width: 2px 2px 0 0;
  border-style: solid;
  top: 0;
  bottom: 0;
  height: 5px;
  width: 5px;
  right: -18px;
  margin: auto;
  transform: rotate(135deg);
}
.product-details .controls > .codespan-div + .codespan-div {
  margin-top: 20px;
  flex: none;
}
.product-details .controls ul {
  display: flex;
  margin: 15px 5px;
}
.product-details .color li + li {
  margin-left: 15px;
}
.product-details .colors {
  display: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: block;
}
.color {
  display: none;
}
.play-yt-vid-icon {
  margin-top: 20px;
  width: 40px;
  cursor: pointer;
  height: 40px;
  fill: #00a5ec;
}
.play-yt-vid-icon:hover {
  fill: #008fcc;
}

.product-details .rate {
  position: static;
  margin-top: 10px;
}
.product-details .rate a {
  font-size: 18px;
  color: #bbb;
}
.product-details .rate a.active,
.product-details .rate a:hover {
  color: #fe6067;
}
.dots {
  /* display: flex; */
  margin-top: 50px;
}
.dots > a {
  background-color: #349a98;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
}
.dots > a:hover,
.dots > a.active {
  background-color: white;
}
.dots i {
  display: none;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer > button {
  display: flex;
  border: 0;
  padding: 15px 25px;
  align-items: center;
  border-radius: 7px;
  cursor: pointer;
  transition: 0.5s;
  animation: forwards;
}
.footer > button:hover {
  background-color: #0460ca;
}
.footer > button > svg {
  width: 22px;
  margin-bottom: 5px;
  fill: white;
  transition: 0.4s;
}
.footer > button > svg:hover {
  width: 22px;
  margin-bottom: 5px;

  transform: rotate(-50deg);
}
.footer > button > span {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
  margin-left: 10px;
  color: white;
}
.footer > a svg {
  width: 24px;
  opacity: 0.8;
}
.footer > a:hover svg {
  opacity: 1;
}
.show-more {
  border-radius: 20px;
}
@media (min-width: 37.5em) {
  .product-icon {
    width: 100%;
    padding-right: 0px;
    display: flex;
    justify-content: center;
  }
  .product-details .rate {
    position: absolute;
    top: 12px;
    right: 10px;
    margin-top: 0;
  }
  .product-details .controls > .codespan-div.qty {
    width: 60px;
  }
  .product-details .controls > .codespan-div + .codespan-div {
    border-left: 2px solid rgba(187, 187, 187, 0.5);
    padding-left: 25px;
    padding-right: 25px;
    width: 100px;
    margin-top: 0;
  }
  .product-details .controls {
    display: flex;
  }

  .product-image {
    padding-top: 0px;
  }
  .product-pic {
    margin: 10px;
    max-width: 400px;
  }
}
@media (min-width: 56.25em) {
  .container-prod {
    display: flex;
    flex-direction: row;
    align-items: normal;
    margin: auto;
  }
  .product-image {
    border-radius: 20px 0 0 20px;
    max-width: 330px;
    margin-bottom: 0px;
    /* border: 2px solid red; */
  }
  .product-pic {
    /* left: -40px; */
    max-width: 330px;
  }
  .product-details {
    width: 100%;
    border-radius: 0 20px 20px 0;
  }
}
@media (min-width: 10px) and (max-width: 425px) {
  .container-prod {
    margin-top: 0px;
  }
}

@media (min-width: 10px) and (max-width: 768px) {
  .medias-wrap {
    padding-top: 0px;
  }

  .product-details {
    padding: 20px 20px 0px 20px;
    background-color: white;
    background-color: #f0f0f0;

    border-radius: 0 0 20px 20px;
  }
  .gift-order-btn {
    width: 100vw;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
