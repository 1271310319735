.testimonial-wrap {
    
margin: 0px auto;
}

.sliderDiv {
    /* padding: 20px 190px; */
    width: 100%;
    
    margin: 0px;


}
.about-slide-img {
    margin: 0px auto !important;
    width: 70% !important;
}


.slick-dots{
    display: none !important; 
    margin-top: 50px !important;
}  
.slick-dots-bottom {
    display: none !important;
    margin-top: 50px !important;
}
.slick-list {
    /* display: none !important; */
}

@media (min-width:50px) and (max-width:425px) {
    
    
.about-slide-img {
    margin: 0px auto !important;
    width: 100% !important;
}
}