@font-face {
  font-family: popinsa bold;
  src: url("../fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: popinsa btn;
  src: url(../fonts/Poppins-Medium.ttf);
}

.compwhtsp-cht-con {
  width: 50px;
  z-index: 1;
  position: fixed;
  bottom: 0;
  right: 15px;
  top: 500;
}

.compultra {
  display: flex;
  justify-content: center;
}

.comphero-color-layer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

}

.comphero-wrap {
  width: 100vw;
  max-width: 2060px;
  height: fit-content;
  padding: 70px;
  padding-top: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  background-color: #00215e;
  background-image: url("./media/compbackground.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.compfin-main {
  width: fit-content;
  min-width: 330px;
  min-height: 100px;
}

.comphero-main {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 0px;
  width: 100%;
  height: 100%;
}

.comptest-box-hero {
  height: auto !important;
}

.comphero-quote {
  font-size: 20px;
  font-style: oblique;
}

.comphero-h1 {
  font-family: popinsa bold;
  color: white;
  font-weight: 200;
  width: 65vw;
  font-size: 4rem !important;
  padding: 10px;
  margin: 10px;
}

.comphead-wrap {
  display: flex;
  justify-content: space-around;
}

.comphero-p {
  color: white;
  width: 55vw;

  padding: 10px;
  margin: 10px;
  text-align: left;
  font-size: 19px;
}

.compheroHr {
  width: 100%;
  border: 1px solid white;
  height: 2px;
  margin: 10px;
  background-color: white;
  padding: 0.5px;
  width: 60%;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
}


.comphero-downapp-img-div {
  width: auto;
  height: 480px !important;
  display: flex;
  justify-content: center;
  align-items: end;

}

.comphero-down-app-img {
  padding: 15px;
  display: flex;
  justify-content: center;

  align-items: flex-end;
  width: 200px;
}

.comphero-buttons-wrap {
  width: 100%;
  padding: 10px;
}

.compcr-btn {
  font-size: 13px;
  background-color: #0066db;
  color: white;
  font-weight: 500;
  border: 3px solid #0066db;
  transition: 0.6s;
  letter-spacing: -0.2px;
  font-family: popinsa btn;
  padding: 7px 15px 7px 15px;
  margin: 10px;
}

.compcr-btn:hover {
  background-color: #004edf;
  padding: 7px 30px 7px 30px;
  border-color: #004edf;
}

.comptr-btn {
  font-size: 13px;
  background-color: transparent;
  color: white;
  font-weight: 500;
  border: 3px solid white;
  letter-spacing: -0.2px;
  transition: 0.3s;
  padding: 7px 15px 7px 15px;
  font-family: popinsa btn;

  margin: 10px;
}

.comptr-btn:hover {
  background-color: rgba(255, 255, 255, 0.459);
  color: white;
}

.comphero-starred {
  background-color: transparent !important;
  margin: 10px;

  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  padding: 10px !important;
}

.compstarted-items:before {
  display: none;
}

.comptest-box-wrap {
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 100%;
}

.comptest-box {
  width: 70%;
  border: 2px solid red;
  height: 150px;
}


@media (min-width: 500px) and (max-width: 1024px) {
  .comphero-color-layer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .comphero-wrap {
    width: 100vw;
    height: fit-content;
    justify-content: left;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    background-image: url("./media/compbackground.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .comphero-main {
    height: 100%;
  }

  .comphero-h1 {
    color: white;
    font-weight: 900;
    width: 80vw;
    margin-bottom: 2px !important;
    font-size: 50px;
  }

  .comphero-p {
    color: white;
    font-size: 18px;
    margin-top: 2px;
    width: 60vw;
  }

  .compheroHr {
    border: 1px solid white;
    height: 2px;
    margin: 10px;
    background-color: white;
    padding: 0.5px;
    width: 85%;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
  }

  .comphero-order-btn {
    background-color: rgb(0, 30, 201);
    color: white;
    font-size: 20px;
    font-weight: 500;
    padding: 20px 30px 20px 30px;
    border: none;
    border-bottom: 3px solid rgb(255, 255, 255);
  }
}

@media (min-width: 10px) and (max-width: 500px) {

  .comphero-color-layer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .compfin-main {
    height: fit-content;
    width: 390px !important;
    padding: 3px;
    border: none;
  }

  .comphero-wrap {
    width: 100vw;
    height: fit-content;
    padding: 15px 0px 0px 0px;
    background-image: url("./media/compbackground.jpg");
    background-position: center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;

  }

  .comphero-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px auto;
    width: 100%;
    height: 100%;

  }

  .comphero-buttons-wrap {
    margin-top: 150px;

    padding: 10px;
  }

.comphero-quote {
  font-size: 12px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
  
  .comphero-h1 {
    color: white;
    font-weight: 900;
    font-size: 36px !important;
    min-width: 320px;
    width: 80vw;
    margin-top: 5px;
    margin-bottom: 2px;
  }

  .comphero-p {
    color: white;
    font-size: 13px;
    margin-top: 2px;
    min-width: 320px;
    width: 80vw;

  }

  .compheroHr {
    border: 1px solid white;
    height: 2px;
    margin: 10px;
    background-color: white;
    padding: 0.5px;
    width: 70%;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
  }

  .comphero-buttons-wrap {
    width: 100%;
    margin-top: 130px;
    padding: 10px;
  }

  .comphero-order-btn {
    background-color: rgb(0, 30, 201);
    color: white;
    font-size: 20px;
    font-weight: 500;
    padding: 20px 30px 20px 30px;
    border: none;
    border-bottom: 3px solid rgb(255, 255, 255);
  }

  .comptr-btn {
    font-size: 15px;
    background-color: transparent;
    color: white;
    width: 96%;
    font-weight: 300;
    border: 2px solid white;
    letter-spacing: -0.2px;
    transition: 0.3s;
    padding: 8px 15px 8px 15px;
    margin: 5px;
  }

  .compcr-btn {
    font-size: 15px;
    background-color: #13aff0;
    color: white;
    font-weight: 300;
    border: 2px solid #13aff0;
    letter-spacing: -0.2px;
    padding: 8px 15px 8px 15px;
    margin: 5px;
    width: 96%;
  }
}

@media (min-width:10px)and (max-width:320px) {

  .comphero-h1 {
    font-size: 31px;
  }

}

@media (min-width:360px)and (max-width:375px) {
  .compfin-main {
    padding: 0px;
  }


}

@media (min-width:375px)and (max-width:425px) {
  .compfin-main {
    padding: 0px;
  }

}