.caras-prod-wrap {
  width: 300px;
}
.slick-prev:before {
    display: none;
}
.slick-next:before {
    display: none;
}
.slick-arrow {
    display: none !important;
}
/* .slick-initialized {
    width: 300px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.gift-img-div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.caras-prod-wrap img {
  width: 300px;
  padding: 10px;
  height: 300px;
  object-fit: contain;
}
button::before {
    font-size: 15px !important;
    color: #00a5ec  !important;
}
li.slick-active  {
    /* color: pink !important; */
}

ul.slick-dots {
    /* margin-top: 90px; */
    margin-bottom: 40px;
}
