@font-face {
  font-family: poppinsa;
  src: url("../fonts/Poppins-Medium.ttf");
}
.categories-wrap {
  font-family: poppinsa;
  letter-spacing: 1.5px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: scroll;

  padding: 5px 0px 4px 0px;
}
.categories-wrap::-webkit-scrollbar {
  background: rgba(105, 225, 255, 0);

  height: 2px;
}
.categories-wrap::-webkit-scrollbar-thumb {
  background: rgba(105, 225, 255, 0);
  background: linear-gradient(
    90deg,
    rgba(105, 225, 255, 0) 0%,
    rgba(0, 77, 201, 0) 100%
  );
}
.categUl {
  margin: 0%;
  padding: 0%;
  /* padding-left:100px ; */
  color: black;
  list-style: none;
  display: flex;
}
.categLi {
  transition: 0.1s;
  color: white;
  border: none;
  border-radius: 2px;
  background: #0d77ee;
  padding: 0px 10px 0px 10px;
  margin: 2px 5px 2px 5px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.categLi:hover {
  background-color: rgb(0, 93, 234);
}

@media (min-width: 20px) and (max-width: 425px) {
.categories-wrap {
  justify-content: left;
} .categLi {
    transition: 0.1s;
    color: white;
    padding: 0px 20px 0px 20px;
    margin: 2px 5px ;
    width: fit-content;
    height: 30px;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 2px;
  }

  .categories-wrap {
    font-family: poppinsa;
    letter-spacing: 0.5px;
    width: 100vw;
    padding: 0px;
    /* margin: 5px 0px 5px 0px ; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* background-color: blue; */
    overflow-x: scroll;
  }
}

/* CSS */

.show-more {
  align-items: center;
  background-color: #06f;
  border: 2px solid #06f;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  justify-content: center;
  letter-spacing: -0.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.show-more:focus {
  color: #171e29;
}

.show-more:hover {
  background-color: #3385ff;
  border-color: #3385ff;
  fill: #06f;
}

.show-more:active {
  background-color: #3385ff;
  border-color: #3385ff;
  fill: #06f;
}

@media (min-width: 768px) {
  .show-more {
    min-width: 120px;
  }
}

/* Templated Card */
