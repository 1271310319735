
@font-face {
  font-family: popinsa bold;
  src: url("../fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: popinsa btn;
  src: url(../fonts/Poppins-Medium.ttf);
}

.whtsp-cht-con {
  width: 50px;
  z-index: 1;
  position: fixed;
  bottom: 0;
  right: 15px;
  top: 500;
}

.ultra {
  display: flex;
  justify-content: center;
}

.hero-color-layer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.hero-wrap {
  width: 100vw;
  max-width: 2060px;
  height: fit-content;
  padding: 70px;
  padding-top: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  background-color: #00215e;
  background-image: url("../medias/abstract-surface-textures-white-concrete-stone-wall.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.fin-main {
  width: fit-content;
  min-width: 330px;
  min-height: 100px;
}

.hero-main {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 0px;
  width: 100%;
  height: 100%;
}

.test-box-hero {
  height: auto !important;
}



.hero-h1 {
  font-family: popinsa bold;
  font-weight: 200;
  color: #0d77ee;
  text-shadow: -1px 2px 2px rgba(0, 0, 0, 0.11);
  width: 65vw;
  font-size: 4rem !important;
  padding: 10px;
  padding-left: 0px;
  margin-left: 0px;
  
}

.hero-quote {
  font-family: popinsa btn;
  font-size: 16px;
  color: none;
  text-shadow: none;
  color: black;
}

.head-wrap {
  display: flex;
  justify-content: space-around;
}

.hero-p {
  color: gray;
  width: 55vw;
  padding: 10px;
  margin: 10px;
  text-align: left;
  font-size: 19px;
  padding-left: 0px;
  margin-left: 0px;

}

.heroHr {
  width: 100%;
  border: 1px solid gray;
  height: 2px;
  margin: 10px;
  background-color: gray;
  padding: 0.5px;
  width: 70%;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0px;
  margin-left: 0px;

}

.hero-downapp-img-div {
  width: auto;
  height: 480px !important;
  display: flex;
  justify-content: center;
  align-items: end;
}

.hero-down-app-img {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 200px;
}

.hero-buttons-wrap {
  width: 100%;
  padding: 10px;
  padding-left: 0px !important;
  margin-left: 0px;

}

.cr-btn {
  font-size: 13px;
  background-color: #0066db;
  color: white;
  font-weight: 500;
  border: 3px solid #0066db;
  transition: 0.6s;
  letter-spacing: -0.2px;
  font-family: popinsa btn;
  padding: 7px 15px 7px 15px;
  margin: 10px;
  /* padding-left: 0px; */
  margin-left: 0px;

}

.cr-btn:hover {
  background-color: #004edf;
  padding: 7px 30px 7px 30px;
  border-color: #004edf;
}

.tr-btn {
  font-size: 13px;
  background-color: transparent;
  color: black;
  font-weight: 500;
  border: 3px solid black;
  letter-spacing: -0.2px;
  transition: 0.3s;
  padding: 7px 15px 7px 15px;
  font-family: popinsa btn;
  margin: 10px;
}

.tr-btn:hover {
  background-color: rgba(255, 255, 255, 0.459);
  color: rgb(0, 0, 0);
}

.hero-starred {
  background-color: transparent !important;
  margin: 10px;
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  padding: 10px !important;
  padding-left: 0px;
  margin-left: 0px;

}

.started-items:before {
  display: none;
}

.test-box-wrap {
  display: flex;
  justify-content: center;
  padding: 10px;
  padding-left: 0px;
  margin-left: 0px;

  width: 100%;
}

.test-box {
  width: 70%;
  border: 2px solid red;
  height: 150px;
}

.vectorImage {
  width: 1000px;
}

.animated-image2 {
  width: 100%;
  display: flex;
  display: none;
  justify-content: center;
  animation-name: moveUpDown;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}


.animated-image {
  animation-name: moveUpDown;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }

  100% {
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media(min-width: 2040px) and  (max-width: 2560px) {
  
  .animated-image {
    display: none;
  }
}

@media(min-width: 425px) and  (max-width: 768px) {

  .hero-h1 {
    width: 80vw !important;
    font-size: 3rem !important;
  }
}
@media (max-width: 660px) {
  .content2{
    font-size: 30px;
    font-family: popinsa btn ;
    font-size: large;
  }
}
@media (max-width: 768px) {
.animated-image {
  display: none;
}
  .hero-wrap {
    padding: 40px;
  }

  .hero-h1 {
    width: 100vw;
    font-size: 3rem !important;
  }

  .hero-p {
    width: 100%;
    font-size: 16px;
  }

  .hero-downapp-img-div {
    height: auto !important;
  }

  .hero-down-app-img {
    width: 150px;
  }
}

@media (max-width: 480px) {
  .animated-image2 {
    width: 90%;
    /* border: 2px solid red; */
    display: flex;
    justify-content: center;
    animation-name: moveUpDown;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  .hero-wrap {
    padding: 20px;
  }

  .hero-h1 {
    margin-top: -20px !important;
    font-size: 2.5rem !important;
  }

  .hero-p {
    margin-top: -20px !important;
    width: 200px;
    font-size: 14px;
  }

  .illustratorImage {
    position: relative;
    bottom: 100px;
    left: 90px;
    width: 500px;
  }

  .hero-downapp-img-div {
    height: auto !important;
  }

  .hero-down-app-img {
    width: 120px;
  }
}

/* @media (min-width: 500px) and (max-width: 1024px) {
  .hero-color-layer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .hero-wrap {
    width: 100vw;
    height: fit-content;
    justify-content: left;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    background-image: url("./media/heroBack.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .hero-main {
    height: 100%;
  }
  .hero-h1 {
    color: white;
    font-weight: 900;
    width: 80vw;
    margin-bottom: 2px !important;
    font-size: 50px;
  }
  .hero-p {
    color: white;
    font-size: 18px;
    margin-top: 2px;
    width: 60vw;
  }

  .heroHr {
    border: 1px solid white;
    height: 2px;
    margin: 10px;
    background-color: white;
    padding: 0.5px;
    width: 85%;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
  }

  .hero-order-btn {
    background-color: rgb(0, 30, 201);
    color: white;
    font-size: 20px;
    font-weight: 500;
    padding: 20px 30px 20px 30px;
    border: none;
    border-bottom: 3px solid rgb(255, 255, 255);
  }
}

@media (min-width: 10px) and (max-width: 500px) {

  .hero-color-layer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .fin-main {
    height: fit-content;
    width: 390px !important;
    padding: 3px;
    border: none;
  }

  .hero-wrap {
    width: 100vw;
    height: fit-content;
    padding: 15px 0px 0px 0px;
    background-image: url("./media/mob.png");
    background-position: center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;

  }

  .hero-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px auto;
    width: 100%;
    height: 100%;

  }

  .hero-buttons-wrap {
    margin-top: 150px;

    padding: 10px;
  }

.hero-quote {
  font-size: 12px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
  
  .hero-h1 {
    color: white;
    font-weight: 900;
    font-size: 36px !important;
    min-width: 320px;
    width: 80vw;
    margin-top: 5px;
    margin-bottom: 2px;
  }

  .hero-p {
    color: white;
    font-size: 13px;
    margin-top: 2px;
    min-width: 320px;
    width: 80vw;

  }

  .heroHr {
    border: 1px solid white;
    height: 2px;
    margin: 10px;
    background-color: white;
    padding: 0.5px;
    width: 70%;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
  }

  .hero-buttons-wrap {
    width: 100%;
    margin-top: 130px;
    padding: 10px;
  }

  .hero-order-btn {
    background-color: rgb(0, 30, 201);
    color: white;
    font-size: 20px;
    font-weight: 500;
    padding: 20px 30px 20px 30px;
    border: none;
    border-bottom: 3px solid rgb(255, 255, 255);
  }

  .tr-btn {
    font-size: 15px;
    background-color: transparent;
    color: white;
    width: 96%;
    font-weight: 300;
    border: 2px solid white;
    letter-spacing: -0.2px;
    transition: 0.3s;
    padding: 8px 15px 8px 15px;
    margin: 5px;
  }

  .cr-btn {
    font-size: 15px;
    background-color: #13aff0;
    color: white;
    font-weight: 300;
    border: 2px solid #13aff0;
    letter-spacing: -0.2px;
    padding: 8px 15px 8px 15px;
    margin: 5px;
    width: 96%;
  }
}

@media (min-width:10px)and (max-width:320px) {

  .hero-h1 {
    font-size: 31px;
  }

}

@media (min-width:360px)and (max-width:375px) {
  .fin-main {
    padding: 0px;
  }


}

@media (min-width:375px)and (max-width:425px) {
  .fin-main {
    padding: 0px;
  }

} */