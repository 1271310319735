@font-face {
  font-family: poppinsa-l;
  src: url(../fonts/Poppins-LightItalic.ttf);
}
@font-face {
  font-family: poppinsa-b;
  src: url(../fonts/Poppins-ExtraBold.ttf);
}
/* ultra */
.ultra-all-wrap {
  display: flex;
  justify-content: center;
}
.ultra-all {
  max-width: 2060px;
}

/* Swal Button */
.swal2-styled.swal2-confirm {
  background-color: #0d77ee;
}
.laya-head-wrap {
  display: flex;
  justify-content: center;
}
.laya-head {
  text-align: right;
  max-width: 1000px;
  padding: 20px 0px;
}
.laya-head h1 {
  text-align: center;
  font-family: poppinsa-b;
}
.laya-head p {
  text-align: left;
  font-family: poppinsa-L;
  padding: 14px;
}
.laya-down {
  display:grid;
  padding: 30px;
  justify-content: center;
}

.laya-down button {
  border: 2px solid white;
  background-color: #0d77ee;
  color: white;

  /* padding: 5px 25px; */
}

@media screen and (max-width: 768px) {
  .laya-left {
    width: 100%;
  }
  .laya-middle {
    display: none;
  }
  .laya-main-wrap {
    padding: 0px;
  }
  .laya-main {
    width: 100%;
    flex-wrap: wrap;
    /* height: fit-content; */
  }
  .laya-left {
    display: flex;
    height: 100%;
  }
  .laya-1 {
    padding-left: 0px !important ;
  }
  .laya-left img {
    width: 100%;
    height: 100%;
    padding-left: 10px;
  }
  .laya-right {
    width: 100%;
    height: 100%;
  }
  .laya-right img {
    width: 100%;
    padding: 10px;
  }
}

/* Template */
@media (min-width: 1024px) and (max-width: 2560px) {
  .laya-new-container {
    max-width: 2060px;
    background-color: transparent;
    box-sizing: border-box;
    height: 280px !important;
  }
}

.laya-new-container {
  display: flex;
  width: 100vw;
  max-width: 2060px;
  background-color: transparent;
  /* padding: 4% 2%; */
  padding: 10px;
  box-sizing: border-box;
  height: 180px;
}

.laya-new-box img {
}
.laya-new-box:hover img {
}
.laya-new-box:hover {
  background-color: black;
}
.laya-new-box {
  flex: 1;
  overflow: hidden;
  transition: 0.5s;
  margin: 0 2%;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.1);
  line-height: 0;
}

.laya-new-box > img {
  width: 200%;
  height: 100%;
  /* height: calc(100% - 10vh); */
  object-fit: cover;
  transition: 0.5s;
}

.laya-new-box > span {
  font-size: 3.8vh;
  /* display: block; */
  /* text-align: center; */
  /* height: 10vh; */
  /* line-height: 2.6; */
}

.laya-new-box:hover {
  flex: 1 1 50%;
}
.laya-new-box:hover > img {
  width: 100%;
  height: 100%;
}
