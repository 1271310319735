@font-face {
  font-family: poppinsb;
  src: url("../../fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: poppinsL;
  src: url("../../fonts/Poppins-Medium.ttf");
}


.Register-form-wrap  {
  max-width: 2040px !important;
  margin: 0px auto;
}

.sigin-form-wrap {
  width: 100vw;
  max-width: 2040px;
  display: flex;
  justify-content: center;
}
.sigin-form {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: transparent;
  margin: 30px 100px 30px 100px;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.693);
  flex-wrap: wrap;
  width: 100%;
}

.login-notes-right {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 500px;
  justify-content: center;
  background-color: rgb(0, 125, 255);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1237%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='url(%23SvgjsLinearGradient1238)'%3e%3c/rect%3e%3cpath d='M1440 0L843.67 0L1440 184.8z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M843.67 0L1440 184.8L1440 240.09L619.78 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M619.78 0L1440 240.09L1440 327.9L521.62 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M521.62 0L1440 327.9L1440 338.27L159.3 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 560L349.32 560L0 331.36z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 331.36L349.32 560L766.1800000000001 560L0 198.17000000000002z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 198.17000000000002L766.1800000000001 560L813.5100000000001 560L0 144.33z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 144.33000000000004L813.5100000000001 560L1056.5800000000002 560L0 61.00000000000004z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1237'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='15.28%25' y1='-39.29%25' x2='84.72%25' y2='139.29%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1238'%3e%3cstop stop-color='rgba(0%2c 125%2c 255%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(0%2c 90%2c 206%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
}
.login-form-left {
  padding-left: 60px  ; 
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;

  background-color: white;
  width: 50%;
}
.login-h1 {
  color: white;
  text-align: center;
  width: 100%;
  font-family: poppinsb;
  font-size: 50px;
  margin-bottom: 60px !important;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.276);
}
.company-p {
  color: white;
  text-align: center;
  width: 100%;
  padding: 20px;
  font-family: poppinsL;
}

.ant-form {
  width: auto;
  /* border: 2px solid green; */
  width: 80%;
  margin: 0px auto;
}
.login-form {
  /* margin-top:20px ; */
}
.login-form-button-custom {
  width: 80%;
}
.ant-input-affix-wrapper {
  background-color: transparent;
  width: 80%;
  display: flex;
  justify-content: center;
  padding: 10px;
}
/* under inp box  */
.ant-input.css-dev-only-do-not-override-1ij74fp {
  background-color: transparent;
}
.ant-input.css-dev-only-do-not-override-1ij74fp::placeholder {
  color: rgba(0, 0, 0, 0.637);
}

@media (min-width: 100px) and (max-width: 768px) {
  .sigin-form {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0px;
    background-color: green;
    flex-wrap: wrap;
    /* border: 5px solid rgb(0, 58, 121); */
    width: 100%;
    height: auto;
    text-align: center;
  }
  .ant-form {
    width: 100%;
  }
  .login-notes-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* border-bottom-left-radius: 10px ; */
    /* border-bottom-right-radius: 10px; */
    height: auto;
    background: #f0f0f0;
    z-index: 1;
    margin-bottom: -80px;
    justify-content: center;
  }
  .login-form-left {
    border-top-left-radius: 0px;
    padding-top: 60px;
    padding-left: 0px;
    border-top-right-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    background: #f0f0f0;

  }
  a {
    color: black;
    text-decoration: none;
  }
  .login-form-login-forgot {
    display: none;
  }
  .login-h1 {
    width: 100%;
    padding-top: 29px;
    color: rgb(0, 125, 255);
    text-shadow: none;
    font-size: 30px;
  }
  p.company-p {
    display: none;
    font-weight: 300;
    text-align: center;
    width: 100%;
    font-size: 12px;
    padding: 10px;
    color: white;
  }

  .ant-input-affix-wrapper {
    margin: 0px auto;
  }
  .sigin-form {
    box-shadow: none;
  }
}
