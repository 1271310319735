@font-face {
    font-family: poppinsaB;
    src: url(../../fonts/Poppins-Bold.ttf);
}
@font-face {
    font-family: poppinsaM;
    src: url(../../fonts/Poppins-Medium.ttf);
}
@font-face {
    font-family: poppinsaL;
    src: url(../../fonts/Poppins-Light.ttf);
}
.p-s {
    padding: 0px 3px;
}
.b {
    font-weight: 600;
}
.s {
    font-size: 22px;
    padding: 5px;
    border-radius: 5px;
    font-family: poppinsaL;}
.df-head h1 {
    font-family: poppinsaB;
}
.df-head h1 {
    padding: 20px;
    padding-bottom: 0px;
}

.df-body-wrap {
    /* border: 1px solid red; */
    /* display: flex; */
    margin: 0px auto;
    max-width:1500px ;
}
.df-body {
    /* border: 1px solid; */
    font-family: poppinsaL;
    margin: 0px auto;
padding: 30px   10px;
padding-top: 15px;
    line-height: 32px;
    /* max-width: 850px; */
    font-size: 20px;
}
.df-box {
    margin: 70px 0px;
}
.df-box h2 {
    font-family: poppinsaM;
 
    margin: 0px ;
}
.df-box ul {
    padding: 0px 30px;
}
.df-box ul li {
    padding: 0px 10px;
    color: black;
    list-style: disc;
    list-style-position: outside;
}