.order-form {
  border-radius: 3px !important;
  padding: 5px !important;
  font-size: 15px !important;
  background-color: transparent ;
  height: 40px !important;
  border: none !important;
  border-bottom: 1px solid rgba(128, 128, 128, 0.665) !important;
  transition: 0.2s;
}
.order-form:focus {
    background-color:  transparent;
    /* border: none !important; */
    border-bottom-color: #42aeec!important;
    /* border-bottom: 1.5px solid rgba(128, 128, 128, 0.665) !important; */
}

.divider-text {
  position: relative;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
}
.divider-text span {
  padding: 7px;
  font-size: 12px;
  position: relative;
  z-index: 2;
}
.divider-text:after {
  content: "";
  position: absolute;
  width: 100%;
  border-bottom: 1px solid #ddd;
  top: 55%;
  left: 0;
  z-index: 1;
}

.btn-facebook {
  background-color: #405d9d;
  color: #fff;
}
.btn-twitter {
  background-color: #42aeec;
  color: #fff;
}
.form-control {
  padding: 10px;
}
