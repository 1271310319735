@font-face {
  font-family: poppinsaB;
  src: url(../fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: poppinsaL;
  src: url(../fonts/Poppins-Light.ttf);
}
.laya-ultra-wrap {
  display: flex;
  justify-content: center;

}
  .laya-ultra {
  max-width: 2060px;
}
.SwitchBreakaway-main-wrap {
  display: flex;
  justify-content: center;
  padding: 0px;
}
.breakaway-w {
  color: white;
}
.SwitchBreakaway-main {
  background-color: #0093e9;
  background-image: linear-gradient(160deg, #0d77ee 0%, #61c8bc 100%);
  padding: 30px 90px;
  width: 100vw;
  padding-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.SwitchBreakaway-right {
  width: fit-content;
  max-width: 650px;
}
.SwitchBreakaway-right h3 {
  font-family: poppinsaB;
  font-size: 2.5rem;
}
.switch-brkhead-p {
  font-family: poppinsaL;
  color: white;
}
.sbrown-wrap {
  background-color: #f0f0f0;
  border-radius: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  cursor: pointer;
}

.sbrown-wrap:hover .sbrown-icon {
  font-size: 50px !important;
  transition: 0.2s;
  margin: 0px 0px 7px 0px;
  /* box-shadow:; */
}
.sbrown {
  padding: 5px;
}
.sbrown-icon {
  font-size: 45px !important;
  margin: 0px 0px 12px 0px;
}

.sbrown p {
  font-size: 11px;
  margin: 0px !important;
}
.SwitchBreakaway-right-down {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 20px;
}
.footer-of-switchbreak p {
  color: transparent;
  opacity: 0.2;
  text-align: right;
}

.footer-of-switchbreak button {
  margin-top: 20px;
  color: white;
  font-family: poppinsaL;
  background-color: transparent;
  border: none;
  border-left: 2px solid #0d77ee;
  text-align: right;
  transition: 0.2s;
  padding: 6px 10px;
}
.footer-of-switchbreak button:hover {
  background-color: #0d77ee;
}

@media (min-width: 425px) and (max-width: 662px) {
  .sbrown-wrap {
    margin: 25px !important;
  }
}
@media (min-width: 370px) and (max-width: 425px) {
  .sbrown-wrap {
    margin: 25px !important;
  }
}
@media (max-width: 425px) {
  .SwitchBreakaway-main {
    padding: 15px;
  }
  .SwitchBreakaway-right-down {
    width: 100%;
    padding-top: 0px;
    margin: 0px;
    justify-content: center;
  }
  .sbrown-wrap {
    margin: 10px;
  }
  .sbrown {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .SwitchBreakaway-left {

    /* background-image: url("../medias/a\ man\ horizontal.png"); */
    background-image: url("https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg");

    /* background-image: url("./media/indicatingDown.png"); */
    width: 100vw;
    height: 200px;
    background-repeat: no-repeat;
    background-attachment: local;
    background-position-x: center;
    background-position: center;
    background-size: contain;
    border-bottom: 4px solid white;
  }
  .SwitchBreakaway-left img {
    display: none;
  }
}

@media (max-width:1070px) {
  .SwitchBreakaway-right {
    margin-bottom: 20px !important;
  }
  .SwitchBreakaway-left {
    background-image: url("../medias/comp.png");
    /* background-image: url("../medias/car.png"); */
    width: 100vw;
    height: 250px;
    background-repeat: no-repeat;
    background-attachment: local;
    background-position-x: center;
    background-position: center;
    background-size:contain;
    border-bottom: 4px solid white;
  }
  .SwitchBreakaway-left img {
    display: none;
  } 
  .footer-of-switchbreak {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
@media (min-width:1024px) and (max-width:2560px) {
 
  .SwitchBreakaway-main {
    justify-content: space-evenly;
    padding-top: 50px;
    padding-left: 10px ;
    padding-right: 10px ;  
  }
  .SwitchBreakaway-left img {
width: 400px;
  }
  .footer-of-switchbreak {
    height: 40%;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    padding-top: 50px;
  }
}