@font-face {
  font-family: popBold;
  src: url("../fonts/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: popLight;
  src: url("../fonts/Poppins-Medium.ttf");
}
/* @font-face {
  font-family: popLight;
  src: url("../fonts/Poppins-Light.ttf");
} */
.con-main-wrapper {
  padding: 10 220px !important;
}
/* .cont-head {
} */
.head-wrapp {
  padding: 0px;
  background-image: url("./media/lapandcop.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  /* display: flex;
	justify-content: center; */
}
.bolda {
  font-family: popBold;
  position: relative;
  bottom: 10px;
}
.cont-head-main {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50vh;
  background-color: rgba(0, 51, 203, 0.671);
  padding: 0px 15px 15px 15px;
  margin-bottom: 50px;
}
.head-data {
  text-align: center;
  margin: 0px auto;
}
.head-data button {
  border: 2px solid white;
  background-color: transparent;
  color: white;
  padding: 7px 12px;
  margin: 20px;
  transition: 0.2s;
}

.head-data button:hover {
  color: rgb(0, 14, 96);
  background-color: rgba(255, 255, 255, 0.808);
}
.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.cont-h1 {
  color: white;
  /* text-align: left !important; */
  font-size: 3rem !important;
  font-family: popBold;
}
.cont-p {
  /* text-align: left; */
  color: white;
  margin-bottom: 0px !important;
}
.map-head {
  text-align: center;
  /* background-color: rgba(0, 150, 244, 0.794); */
  padding: 10px;
}
.map-h1 {
  color: #0d77ee;
  /* text-align: left !important; */
  font-size: 3rem !important;
  font-family: popBold;
}
.map-p {
  color: #1b1b1be1;
  margin-bottom: 0px !important;
  margin: 0px 50px;
}
.cont-btn:hover {
  background-color: #006ce8;
}
.cont-btn {
  border: none;
  cursor: pointer;
  background-color: #0d77ee;
  color: white;
  padding: 10px 10px;
  height: 50px;
  border-radius: 5px;
  width: 140px;
}
@media (min-width: 10px) and (max-width: 425px) {
  .cont-h1 {
    font-size: 2rem !important;
  }
  .cont-p {
    font-size: 13px;
  }
  .head-data button {
    border: 1px solid white;
    padding: 4px 8px;
    margin: 20px;
    font-size: 13px;
  }
  .hr-cotact {
    display: none;
  }
  .info-wrap {
    display: none;
  }
}

.form-control {
  font-family: popLight;
  height: 36px;
  background: #fff;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.024);
  -webkit-box-shadow: none !important;
}
.container-map {
  /* wid */
  padding: 0px !important;
}
.form-control:focus,
.form-control:active {
  border-color: #46b5d1 !important;
}

textarea.form-control {
  height: inherit !important;
}

.wrapper {
  width: 100%;
}

.contact-wrap {
  background: #fff;
}

@media (min-width:10px) and (max-width:425px) {
	.map-h1 {
		font-size: 30px !important;
	  }}
	  .map-p {
		font-size: 13px;
	  }
@media (max-width: 767.98px) {
  
	.info-wrap {
    height: 400px;
  }
}

.dbox {
  width: 100%;
  margin-bottom: 25px;
}
@media (max-width: 767.98px) {
  
  .dbox {
    margin-bottom: 25px !important;
    padding: 0 20px;
  }
}
.dbox p {
  font-family: popLight;
  margin-bottom: 0;
}
.dbox p span {
  font-weight: 500;
  color: #000;
}
.dbox p a {
  color: #46b5d1;
}
.dbox .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #0d77ee;
  margin: 0 auto;
  margin-bottom: 20px;
}
.dbox .icon span {
  font-size: 20px;
  color: #fff;
}
.dbox .text {
  width: 100%;
}

.btns {
  padding: 12px 16px;
  cursor: pointer;
  border-width: 1px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  margin-bottom: 20px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.contactForm .label {
  color: #000;
  text-transform: uppercase;
  font-size: 12px;
  /* font-weight: 600;  */
}

.contactForm .form-control {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
}

@media (max-width:425px) {
  .map-p  {
    margin: 0px 10px;
  }
  
}