


/* From Main page Css */
.business-hero-wrap {
  padding-left: 15px;
  padding-right: 40px;
  width: 100vw;
  max-width: 2060px;
  height: fit-content;
  padding: 70px;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  background-color: #00215e;
  background-image: url("https://www.teahub.io/photos/full/18-189916_eraashu001-images-apps-for-business-hd-wallpaper-and.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
/* From Main page Css End */
.comp-reg-main-wrap {
  font-family: poppinsaNormal;

}

/*  */
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: none;
  background-color: #333;
  color: #fff;
  height: 500px;
  text-align: center;
}

.hero__title {
  font-size: 36px;
  margin-top: 100px;
}

.hero__subtitle {
  font-size: 24px;
  margin-top: 20px;
}

@media (max-width: 767px) {
  .hero {
    height: 300px;
  }

  .hero__title {
    font-size: 24px;
    margin-top: 50px;
  }

  .hero__subtitle {
    font-size: 16px;
    margin-top: 10px;
  }
}

/*  */

@font-face {
  font-family: poppinsab;
  src: url(../../fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: poppinsaNormal;
  src: url(../../fonts/Poppins-Medium.ttf);
}
.company-ord-btn {
  margin: 10px 0px  ;
  padding: 5px 20px;
  background-color: #0d77ee;
  color: white;
  border: none;
}
.comp-reg-btn {
  border: 2px solid white;
  background-color: transparent;
  color: white;
  padding: 3px 20px;
  margin: 0px 5px;
}
.fifth-sec{
  font-family:  poppinsaNormal !important;
}
@media (min-width: 50px) and (max-width: 425px) {
  .hero-comp-nice {
  padding: 0px !important;
  }
  .veryunder {
    padding:15px !important ;
    margin-top: 0vh !important;
  }
  .veryunder {
    padding-top: 0px;
    padding: 10px;
    padding-left: 10px;
  }
  .veryunder p {
    text-align: justify;
  }
  .hide-mob {
    display: none !important;
  }
}
@media (min-width: 425px) {
  .main-comp-body {
    padding: 10px 10px;
    }
  .hide-mob {
    display: none !important;
  }

  

}
@media (min-width: 500px) and (max-width: 768px) {
  .main-comp-body {
    padding: 20px 60px;
  }
  .veryunder {
    padding: 20px;
    padding-left: 50px;
  }
  .hide-mob {
    display: none !important;
  }

}
@media (min-width: 768px) and (max-width: 2560px) {
  .main-comp-body {
    padding: 20px 80px;
  }
  .veryunder {
    padding: 20px;
    padding-left: 50px;
  }
}

.align-center {
  text-align: center !important;
}

.u-section-1 {
  /* background-image: url("images/pexels-photo-273209.jpeg"); */
  background-image: url("https://images.pexels.com/photos/936137/pexels-photo-936137.jpeg?cs=srgb&dl=pexels-nappy-936137.jpg&fm=jpg");
  background-position: 50% 50%;
  /* padding: 50px; */
}
.hero-comp-nice {
  padding: 0px ;
}
.main-hero-comp-nicepage {
  display: flex;
  justify-content: center;
}
.under-main-hero-comp-nicepage {
  min-height: 100vh !important;
  /* height: 400px !important; */
  /* border: 3px solid !important; */
}
.veryunder {
  margin-top: 10vh;
  /* height: 500px; */
}
.veryunder h1 {
  font-family: poppinsab;
}
.comp-hero-btn {
  /* margin: 20px;   */
  padding: 10px !important;
  border-radius: 1px !important;
}

.u-section-1 .u-sheet-1 {
  background-color: rgba(0, 11, 36, 0.5);
  
  align-items: center;
}
/* Apply register Section */
.under-regis-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.under-regis {
  height: fit-content;
}
.regist-logo {
  margin:  auto !important;
  margin-bottom: 20px !important;
}
.sec-hero-comp-img {
  width: 100px;
}
.apply-regis-form {
  padding: 30px !important;
}
.u-form-spacing-18 .u-form-group:not(.u-image) {
  padding-left: 0px !important;
}

.u-palette-4-dark-1 {
  background-color: #0d77ee !important;
}

.u-section-1 .u-text-1 {
  font-size: 3.75rem;
  font-weight: 700;
  margin: 83px 346px 0 0;
}

.u-section-1 .u-text-2 {
  font-size: 1.125rem;
  margin: 42px 640px 0 0;
}

.u-section-1 .u-btn-1 {
  background-image: none;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  margin: 55px auto 60px 0;
  padding: 16px 51px;
}

@media (max-width: 1199px) {
  
  .u-section-1 .u-text-1 {
    margin-right: 146px;
  }

  .u-section-1 .u-text-2 {
    margin-right: 440px;
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-sheet-1 {
    min-height: 726px;
  }

  .u-section-1 .u-text-1 {
    font-size: 3rem;
    width: auto;
    margin-top: 232px;
    margin-right: 0;
  }

  .u-section-1 .u-text-2 {
    margin-top: 63px;
    margin-right: 220px;
  }
}

@media (max-width: 767px) {
  .u-section-1 {
    background-position: 50% 50%;
  }

  .u-section-1 .u-sheet-1 {
    min-height: 411px;
  }

  .u-section-1 .u-text-2 {
    margin-right: 40px;
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-sheet-1 {
    min-height: 870px;
  }

  .u-section-1 .u-text-1 {
    font-size: 2.25rem;
    margin-top: 150px;
  }

  .u-section-1 .u-text-2 {
    width: auto;
    margin-top: 138px;
    margin-right: 0;
  }
}
.u-section-2 .u-sheet-1 {
  min-height: 786px;
}

.u-section-2 .u-layout-wrap-1 {
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: auto;
}

.u-section-2 .u-layout-cell-1 {
  min-height: 666px;
  --animation-custom_in-translate_x: -200px;
  --animation-custom_in-translate_y: 0px;
  --animation-custom_in-opacity: 0;
  --animation-custom_in-rotate: 0deg;
  --animation-custom_in-scale: 1;
}

.u-section-2 .u-container-layout-1 {
  padding: 30px;
}

.u-section-2 .u-text-1 {
  font-weight: 700;
  font-size: 3rem;
  margin: 0 auto 0 0;
}

.u-section-2 .u-text-2 {
  font-style: italic;
  font-size: 1.5rem;
  line-height: 1.2;
  text-align: left;
  font-weight: 700;
  margin: 65px 60px 0 0;
}

.u-section-2 .u-text-3 {
  font-size: 1rem;
  text-align: justify;
  margin: 20px 0 0;
}

.u-section-2 .u-btn-1 {
  border-style: none;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 30px auto 0 0;
  padding: 0;
}

.u-section-2 .u-icon-1 {
  font-size: 1.2em;
}

.u-section-2 .u-layout-cell-2 {
  min-height: 666px;
}

.u-section-2 .u-container-layout-2 {
  padding: 30px;
}

.u-section-2 .u-image-1 {
  width: 255px !important;
  height: 242px !important;
  margin: 0 0 0 auto;
}

.u-section-2 .u-image-2 {
  width: 255px !important;
  height: 242px !important;
  margin: 23px auto 0 0;
}

.u-section-2 .u-group-1 {
  min-height: 252px;
  width: 259px;
  margin: -429px auto 0;
}

.u-section-2 .f-3 {
  padding: 30px;
}

.u-section-2 .u-text-4 {
  font-size: 6rem;
  font-weight: 700;
  margin: 0 0 0 auto;
}

@media (max-width: 1199px) {
  .u-section-2 .u-sheet-1 {
    min-height: 669px;
  }

  .u-section-2 .u-layout-wrap-1 {
    margin-left: initial;
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 549px;
  }

  .u-section-2 .u-text-2 {
    margin-right: 0;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 549px;
  }

  .u-section-2 .u-image-1 {
    width: 205px;
    height: 234px;
  }
}

@media (max-width: 991px) {
  .u-section-2 .u-sheet-1 {
    min-height: 541px;
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-2 .u-text-1 {
    font-size: 2.25rem;
  }

  .u-section-2 .u-text-2 {
    font-size: 1.25rem;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 707px;
  }

  .u-section-2 .u-image-1 {
    width: 218px;
    height: 341px;
  }

  .u-section-2 .u-image-2 {
    margin-top: 14px;
  }

  .u-section-2 .u-group-1 {
    min-height: 184px;
    width: 233px;
    margin-top: -398px;
    margin-right: 21px;
  }

  .u-section-2 .u-text-4 {
    font-size: 4.5rem;
    width: auto;
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (max-width: 767px) {
  .u-section-2 .u-sheet-1 {
    min-height: 852px;
  }

  .u-section-2 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 677px;
  }

  .u-section-2 .u-container-layout-2 {
    padding: 0 10px;
  }

  .u-section-2 .u-image-1 {
    margin-top: 30px;
  }

  .u-section-2 .u-group-1 {
    margin-right: auto;
  }

  .u-section-2 .u-container-layout-3 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-2 .u-text-4 {
    width: 185px;
    margin-right: auto;
  }
}

@media (max-width: 575px) {
  .u-section-2 .u-sheet-1 {
    min-height: 618px;
  }

  .u-section-2 .u-group-1 {
    min-height: 204px;
    width: 210px;
    margin-top: -407px;
  }

  .u-section-2 .u-text-4 {
    width: auto;
    margin-left: 3px;
    margin-right: 3px;
  }
.u-section-3 .u-container-layout-1 {
padding: 50px 30px !important;

}
}
.u-section-3 .u-sheet-1 {
  min-height: 655px;
}

.u-section-3 .u-layout-wrap-1 {
  margin: 0 auto 0 0;
}

.u-section-3 .u-layout-cell-1 {
  min-height: 704px;
  background-image: none;
}

.u-section-3 .u-container-layout-1 {
  padding: 30px 60px;
}

.u-section-3 .u-image-1 {
  width: 85px;
  height: 51px;
  margin: 134px auto 0 177px;
}

.u-section-3 .u-text-1 {
  margin: 20px auto 0;
}

.u-section-3 .u-text-2 {
  margin: 19px 0 0;
}

.u-section-3 .u-btn-1 {
  border-style: none none solid;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 1px;
  background-image: none;
  margin: 20px auto 0;
}

.u-section-3 .u-layout-cell-2 {
  min-height: 704px;
}

.u-section-3 .u-container-layout-2 {
  padding: 30px 0;
}

.u-section-3 .u-form-1 {
  height: 522px;
  --thumb-color: #478ac9;
  --thumb-hover-color: #77aad9;
  --track-color: #c0c0c0;
  --track-active-color: #478ac9;
  width: 510px;
  margin: 0 auto;
}

.u-section-3 .u-form-group-1 {
  width: 100%;
}

.u-section-3 .u-form-group-2 {
  width: 100%;
}

.u-section-3 .u-form-group-3 {
  width: 100%;
}

.u-section-3 .u-form-group-4 {
  width: 100%;
}

.u-section-3 .u-form-group-5 {
  width: 100%;
}

.u-section-3 .u-form-group-6 {
  width: 100%;
}

.u-section-3 .u-btn-2 {
  background-image: none;
  padding: 0;
}

.u-section-3 .u-form-group-7 {
  width: 100%;
}

.u-section-3 .u-btn-3 {
  width: 100%;
  padding: 10px 0;
}

@media (max-width: 1199px) {
  .u-section-3 .u-sheet-1 {
    min-height: 89px;
  }

  .u-section-3 .u-layout-wrap-1 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-3 .u-layout-cell-1 {
    min-height: 580px;
  }

  .u-section-3 .u-btn-1 {
    padding: 8px ;
  }

  .u-section-3 .u-layout-cell-2 {
    background-position: 50% 50%;
    min-height: 580px;
  }

  .u-section-3 .u-form-1 {
    width: 470px;
  }
}

@media (max-width: 991px) {
  .u-section-3 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-3 .u-container-layout-1 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .u-section-3 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-3 .u-form-1 {
    width: 360px;
  }
}

@media (max-width: 767px) {
  .u-section-3 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-3 .u-form-1 {
    width: 340px;
  }
}
.u-section-4 {
  background-image: none;
}

.u-section-4 .u-sheet-1 {
  min-height: 793px;
}

.u-section-4 .u-layout-wrap-1 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.u-section-4 .u-layout-cell-1 {
  min-height: 624px;
  --animation-custom_in-translate_x: -200px;
  --animation-custom_in-scale: 1;
}

.u-section-4 .u-container-layout-1 {
  padding: 30px;
}

.u-section-4 .u-text-1 {
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
}

.u-section-4 .u-image-1 {
  height: 425px;
  width: 425px;
  margin: 30px auto 0;
}

.u-section-4 .u-layout-cell-2 {
  min-height: 703px;
}

.u-section-4 .u-container-layout-2 {
  padding: 30px;
}

.u-section-4 .u-image-2 {
  height: 291px;
  width: 291px;
  margin: 0 9px 0 0;
}

.u-section-4 .u-text-2 {
  margin: 30px 0 0;
}

.u-section-4 .u-btn-1 {
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: 1px;
  background-image: none;
  border-style: none;
  font-weight: 700;
  margin: 20px auto 0 0;
  padding: 17px 54px 18px 53px;
}

@media (max-width: 1199px) {
  .u-section-4 .u-sheet-1 {
    min-height: 670px;
  }

  .u-section-4 .u-layout-cell-1 {
    min-height: 515px;
  }

  .u-section-4 .u-layout-cell-2 {
    min-height: 580px;
  }
}

@media (max-width: 991px) {
  .u-section-4 .u-sheet-1 {
    min-height: 190px;
  }

  .u-section-4 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-4 .u-text-1 {
    font-size: 2.25rem;
  }

  .u-section-4 .u-image-1 {
    height: 335px;
    width: 335px;
  }

  .u-section-4 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-4 .u-image-2 {
    height: 235px;
    width: 235px;
    margin-right: 0;
  }
}

@media (max-width: 767px) {
  .u-section-4 .u-sheet-1 {
    min-height: 290px;
  }

  .u-section-4 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-4 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-4 .u-image-1 {
    height: 320px;
    width: 320px;
  }
}
.u-section-5 .u-sheet-1 {
  min-height: 607px;
}

.u-section-5 .u-layout-wrap-1 {
  margin-top: 60px;
  margin-bottom: -2px;
}

.u-section-5 .u-layout-cell-1 {
  min-height: 487px;
  --animation-custom_in-translate_x: -200px;
  --animation-custom_in-translate_y: 0px;
  --animation-custom_in-opacity: 0;
  --animation-custom_in-rotate: 0deg;
  --animation-custom_in-scale: 1;
}

.u-section-5 .u-container-layout-1 {
  padding: 13px 30px;
}

.u-section-5 .u-text-1 {
  font-size: 3rem;
  font-weight: 700;
  margin: 0;
}

.u-section-5 .u-text-2 {
  font-weight: 700;
  font-size: 1.125rem;
  font-style: italic;
  margin: 20px 0 0;
}

.u-section-5 .u-text-3 {
  font-size: 1rem;
  margin: 20px 0 0;
}

.u-section-5 .u-layout-cell-2 {
  min-height: 487px;
  background-image: none;
}

.u-section-5 .u-container-layout-2 {
  padding: 30px;
}

.u-section-5 .u-list-1 {
  margin-bottom: 0;
  margin-top: 0;
}

.u-section-5 .u-repeater-1 {
  grid-template-columns: repeat(2, calc(50% - 5px));
  min-height: 412px;
  grid-gap: 10px;
}

.u-section-5 .u-container-layout-3 {
  padding: 10px;
}

.u-section-5 .u-text-4 {
  font-weight: 700;
  font-size: 4.5rem;
  margin: 0;
}

.u-section-5 .u-text-5 {
  font-size: 0.875rem;
  margin: 20px 0 0;
}

.u-section-5 .u-container-layout-4 {
  padding: 10px;
}

.u-section-5 .u-text-6 {
  font-weight: 700;
  font-size: 4.5rem;
  margin: 0;
}

.u-section-5 .u-text-7 {
  font-size: 0.875rem;
  margin: 20px 0 0;
}

.u-section-5 .u-container-layout-5 {
  padding: 10px;
}

.u-section-5 .u-text-8 {
  font-weight: 700;
  font-size: 4.5rem;
  margin: 0;
}

.u-section-5 .u-text-9 {
  font-size: 0.875rem;
  margin: 20px 0 0;
}

.u-section-5 .u-container-layout-6 {
  padding: 10px;
}

.u-section-5 .u-text-10 {
  font-weight: 700;
  font-size: 4.5rem;
  margin: 0;
}

.u-section-5 .u-text-11 {
  font-size: 0.875rem;
  margin: 20px 0 0;
}

@media (max-width: 1199px) {
  .u-section-5 .u-sheet-1 {
    min-height: 584px;
  }

  .u-section-5 .u-layout-wrap-1 {
    margin-bottom: 60px;
  }

  .u-section-5 .u-layout-cell-1 {
    min-height: 402px;
  }

  .u-section-5 .u-layout-cell-2 {
    min-height: 402px;
  }

  .u-section-5 .u-repeater-1 {
    min-height: 331px;
  }
}

@media (max-width: 991px) {
  .u-section-5 .u-sheet-1 {
    min-height: 282px;
  }

  .u-section-5 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-5 .u-text-1 {
    font-size: 2.25rem;
  }

  .u-section-5 .u-layout-cell-2 {
    min-height: 100px;
  }

  .u-section-5 .u-repeater-1 {
    grid-template-columns: 100%;
  }
}

@media (max-width: 767px) {
  .u-section-5 .u-sheet-1 {
    min-height: 382px;
  }

  .u-section-5 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-5 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .padding-mobi  {
    padding: 0px 20px !important;
  }
.s2nd-hero-comp {
    padding: 0px 30px;
}
  .u-section-5 .u-text-4 {
    font-size: 3.75rem;
  }

  .u-section-5 .u-text-6 {
    font-size: 3.75rem;
  }

  .u-section-5 .u-text-8 {
    font-size: 3.75rem;
  }

  .u-section-5 .u-text-10 {
    font-size: 3.75rem;
  }
}
.u-section-6 {
  background-image: none;
}

.u-section-6 .u-sheet-1 {
  min-height: 800px;
}

.u-section-6 .u-text-1 {
  font-size: 3rem;
  font-weight: 700;
  margin: 60px 570px 0 0;
}

.u-section-6 .u-list-1 {
  width: 928px;
  margin: 30px 0 60px auto;
}

.u-section-6 .u-repeater-1 {
  grid-template-columns: repeat(2, 50%);
  min-height: 544px;
  grid-gap: 0px;
}

.u-section-6 .u-list-item-1 {
  --animation-custom_in-translate_x: -200px;
  --animation-custom_in-translate_y: 0px;
  --animation-custom_in-opacity: 0;
  --animation-custom_in-rotate: 0deg;
  --animation-custom_in-scale: 1;
}

.u-section-6 .u-container-layout-1 {
  padding: 30px 30px 10px;
}

.u-section-6 .u-image-1 {
  height: 296px;
  margin-top: 0;
  margin-bottom: 0;
}

.u-section-6 .u-text-2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 30px auto 0 0;
}

.u-section-6 .u-text-3 {
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 0;
}

.u-section-6 .u-icon-1 {
  width: 48px;
  height: 48px;
  margin: 30px auto 0 0;
}

.u-section-6 .u-list-item-2 {
  --animation-custom_in-translate_x: -200px;
  --animation-custom_in-translate_y: 0px;
  --animation-custom_in-opacity: 0;
  --animation-custom_in-rotate: 0deg;
  --animation-custom_in-scale: 1;
}

.u-section-6 .u-container-layout-2 {
  padding: 30px 30px 10px;
}

.u-section-6 .u-image-2 {
  height: 296px;
  margin-top: 0;
  margin-bottom: 0;
}

.u-section-6 .u-text-4 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 30px auto 0 0;
}

.u-section-6 .u-text-5 {
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 0;
}

.u-section-6 .u-icon-2 {
  width: 48px;
  height: 48px;
  margin: 30px auto 0 0;
}

@media (max-width: 1199px) {
  .u-section-6 .u-text-1 {
    width: auto;
    margin-right: 333px;
  }
}

@media (max-width: 991px) {
  .u-section-6 .u-sheet-1 {
    min-height: 1694px;
  }

  .u-section-6 .u-text-1 {
    margin-right: 301px;
    font-size: 2.25rem;
  }

  .u-section-6 .u-list-1 {
    width: 720px;
  }

  .u-section-6 .u-repeater-1 {
    grid-template-columns: 100%;
  }

  .u-section-6 .u-image-1 {
    height: 484px;
  }

  .u-section-6 .u-image-2 {
    height: 484px;
  }
}

@media (max-width: 767px) {
  .u-section-6 .u-sheet-1 {
    min-height: 1487px;
  }

  .u-section-6 .u-text-1 {
    margin-right: 121px;
  }

  .u-section-6 .u-list-1 {
    width: 540px;
    margin-bottom: -147px;
  }

  .u-section-6 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-6 .u-image-1 {
    height: 381px;
  }

  .u-section-6 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-6 .u-image-2 {
    height: 381px;
  }
}

@media (max-width: 575px) {
  .u-section-6 .u-sheet-1 {
    min-height: 1244px;
  }

  .u-section-6 .u-text-1 {
    margin-right: 0;
  }

  .u-section-6 .u-list-1 {
    width: 340px;
    margin-bottom: -390px;
  }

  .u-section-6 .u-image-1 {
    height: 234px;
  }

  .u-section-6 .u-image-2 {
    height: 234px;
  }
}
.u-section-7 .u-sheet-1 {
  min-height: 588px;
}

.u-section-7 .u-tabs-1 {
  min-height: 480px;
  height: auto;
  margin: 60px auto 60px 0;
}

.u-section-7 .u-tab-link-1 {
  font-size: 1.5rem;
  background-image: none;
  font-weight: 700;
  padding: 10px 25px;
}

.u-section-7 .u-tab-link-2 {
  font-size: 1.5rem;
  background-image: none;
  font-weight: 700;
  padding: 10px 25px;
}

.u-section-7 .u-tab-pane-1 {
  background-image: none;
}

.u-section-7 .u-container-layout-1 {
  padding: 30px;
}

.u-section-7 .u-image-1 {
  width: 420px;
  height: 367px;
  margin: 0 auto 0 0;
}

.u-section-7 .u-text-1 {
  margin: -367px 0 0 522px;
}

.u-section-7 .u-text-2 {
  margin: 23px 0 0 522px;
}

.u-section-7 .u-text-3 {
  line-height: 1.8;
  margin: 27px 0 0 522px;
}

.u-section-7 .u-tab-pane-2 {
  background-image: none;
}

.u-section-7 .u-container-layout-2 {
  padding: 30px;
}

.u-section-7 .u-text-4 {
  margin: 0 auto 0 0;
}

.u-section-7 .u-text-5 {
  margin: 31px auto 0 0;
}

.u-section-7 .u-btn-1 {
  border-style: none;
  text-transform: uppercase;
  background-image: none;
  margin: 31px auto 0 0;
}

@media (max-width: 1199px) {
  .u-section-7 .u-tabs-1 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-7 .u-image-1 {
    height: 402px;
  }

  .u-section-7 .u-text-1 {
    width: auto;
    margin-top: -402px;
    margin-left: 451px;
  }

  .u-section-7 .u-text-2 {
    width: auto;
    margin-left: 451px;
  }

  .u-section-7 .u-text-3 {
    width: auto;
    margin-left: 451px;
  }
}

@media (max-width: 991px) {
  .u-section-7 .u-image-1 {
    width: 303px;
    height: 427px;
  }

  .u-section-7 .u-text-1 {
    margin-top: -427px;
    margin-left: 330px;
  }

  .u-section-7 .u-text-2 {
    margin-left: 330px;
  }

  .u-section-7 .u-text-3 {
    margin-left: 330px;
  }
}

@media (max-width: 767px) {
  .u-section-7 .u-container-layout-1 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .u-section-7 .u-image-1 {
    width: 178px;
    height: 178px;
  }

  .u-section-7 .u-text-1 {
    margin-top: -177px;
    margin-left: 190px;
  }

  .u-section-7 .u-text-2 {
    margin-left: 190px;
  }

  .u-section-7 .u-text-3 {
    margin-left: 190px;
  }

  .u-section-7 .u-container-layout-2 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 575px) {
  .u-section-7 .u-tabs-1 {
    min-height: 763px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-7 .u-image-1 {
    height: 226px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-7 .u-text-1 {
    margin-top: 14px;
    margin-left: 0;
  }

  .u-section-7 .u-text-2 {
    margin-top: 17px;
    margin-left: 0;
  }

  .u-section-7 .u-text-3 {
    margin-top: 14px;
    margin-left: 0;
  }
}
.u-section-8 .u-sheet-1 {
  min-height: 783px;
}

.u-section-8 .u-gallery-1 {
  height: 520px;
  margin: 59px 0 0;
}

.u-section-8 .u-gallery-inner-1 {
  grid-template-columns: repeat(4, auto);
  grid-gap: 9px;
}

.u-section-8 .u-back-slide-1 {
  background-position: 50% 50%;
}

.u-section-8 .u-back-image-1 {
  object-position: 50% 50%;
}

.u-section-8 .u-over-slide-1 {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );
  padding: 20px;
}

.u-section-8 .u-back-slide-2 {
  background-position: 50% 50%;
}

.u-section-8 .u-back-image-2 {
  object-position: 50% 50%;
}

.u-section-8 .u-over-slide-2 {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );
  padding: 20px;
}

.u-section-8 .u-back-slide-3 {
  background-position: 50% 50%;
}

.u-section-8 .u-back-image-3 {
  object-position: 50% 50%;
}

.u-section-8 .u-over-slide-3 {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );
  padding: 20px;
}

.u-section-8 .u-gallery-item-4 {
  position: relative;
  left: 0;
  top: 0;
}

.u-section-8 .u-back-slide-4 {
  background-position: 50% 50%;
}

.u-section-8 .u-back-image-4 {
  object-position: 50% 50%;
}

.u-section-8 .u-over-slide-4 {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );
  padding: 20px;
}

.u-section-8 .u-gallery-item-5 {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.u-section-8 .u-back-slide-5 {
  background-position: 50% 50%;
}

.u-section-8 .u-back-image-5 {
  object-position: 50% 50%;
}

.u-section-8 .u-over-slide-5 {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );
  transition-duration: 0.5s;
  padding: 20px;
}

.u-section-8 .u-gallery-item-6 {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.u-section-8 .u-back-slide-6 {
  background-position: 50% 50%;
}

.u-section-8 .u-back-image-6 {
  object-position: 50% 50%;
}

.u-section-8 .u-over-slide-6 {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );
  transition-duration: 0.5s;
  padding: 20px;
}

.u-section-8 .u-gallery-item-7 {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.u-section-8 .u-back-slide-7 {
  background-position: 50% 50%;
}

.u-section-8 .u-back-image-7 {
  object-position: 50% 50%;
}

.u-section-8 .u-over-slide-7 {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );
  transition-duration: 0.5s;
  padding: 20px;
}

.u-section-8 .u-gallery-item-8 {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.u-section-8 .u-back-slide-8 {
  background-position: 50% 50%;
}

.u-section-8 .u-back-image-8 {
  object-position: 50% 50%;
}

.u-section-8 .u-over-slide-8 {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.2)
  );
  transition-duration: 0.5s;
  padding: 20px;
}

.u-section-8 .u-text-1 {
  width: 440px;
  margin: 30px auto 0;
}

.u-section-8 .u-social-icons-1 {
  white-space: nowrap;
  height: 32px;
  min-height: 16px;
  width: 116px;
  min-width: 68px;
  margin: 30px auto 59px;
}

.u-section-8 .u-icon-1 {
  height: 100%;
}

.u-section-8 .u-icon-2 {
  height: 100%;
}

.u-section-8 .u-icon-3 {
  height: 100%;
}

@media (max-width: 1199px) {
  .u-section-8 .u-sheet-1 {
    min-height: 448px;
  }

  .u-section-8 .u-gallery-1 {
    height: 429px;
    margin-right: initial;
    margin-left: initial;
  }
}

@media (max-width: 991px) {
  .u-section-8 .u-sheet-1 {
    min-height: 950px;
  }

  .u-section-8 .u-gallery-1 {
    height: 1314px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-8 .u-gallery-inner-1 {
    grid-template-columns: repeat(2, auto);
  }
}

@media (max-width: 767px) {
  
  .u-section-8 .u-sheet-1 {
    min-height: 2441px;
  }

  .u-section-8 .u-gallery-1 {
    height: 3941px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-8 .u-gallery-inner-1 {
    grid-template-columns: repeat(1, auto);
  }
}

@media (max-width: 575px) {
  .u-section-8 .u-sheet-1 {
    min-height: 1613px;
  }

  .u-section-8 .u-gallery-1 {
    height: 2481px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-8 .u-text-1 {
    width: 340px;
  }
}
