@font-face {
  font-family: poppinda;
  src: url(../fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: poplight;
  src: url(../fonts/Poppins-LightItalic.ttf);
}

.all-wrap-feedback {
  display: flex;
  justify-content: center;
  /* width: 100px; */
  /* max-width: 1500px; */
}
.feedbac-wrap {
  background-image: url("./media/girl.jpg");
  /* height: max-content; */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  /* max-width: 1400px; */

}

.fbform-main-wrap {
  /* margin: 20px; */
  display: flex;
  justify-content: space-evenly;
  background-color: #00536d3b;
  flex-wrap: wrap;
  /* width: 100vw; */
  width: 100vw;
  max-width: 2060px;
height: fit-content;
max-height:800px ;
align-items: center;
  
}

.give-us {
  width: 500px;
  color: white;
}
.give-us h2 {
  font-family: poppinda;
}
.feedback-icons {
  padding: 2px;
}
.feedback-iconab {
  border: 2px solid;
  display: none;
  margin: 10px ortant;
}
.give-us p {
  font-family: poplight;
}
.fbform-main {
  color: white;
  display: grid;
  justify-content: center;
  text-align: center;
  padding: 70px 50px;
  width: 550px;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.267);
}

.left-of-feedback {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fbform-main h1 {
  font-family: poppinsa;
}
.fbform-main input {
  border-radius: 2px;
  color: black;
  background-color: #f0f0f0ce;
  border: none;
  margin: 1%;
  padding: 10px;
}
.fbform-main textarea {
  border-radius: 2px;
 
  color: black;
  background-color: #f0f0f0ce;

  border: none;
  margin: 1%;
  padding: 10px;
}
.names-inp-fdbk {
  display: flex;
  justify-content: center;
  width: 100%;
}
.names-inp-fdbk input {
  border-radius: 2px;
 
  padding: 10px;
  background-color: #f0f0f0ce;
  width: 50%;
  margin: 1%;
}
.fbform-main button {
  margin: 1%;
  padding: 10px;
  border: none;
  background-color: #0d77ee;
  /* color: #006894; */
  color: white;
}
.fbform-main button:hover {
  margin: 1%;
  border: none;
  background-color: #006894;
  color: white;
}

.names-inp-fdbk input::placeholder {
  font-size: 14px;
  font-family: poppinda;
  color: gray;
}
.fbform-main textarea::placeholder {
  font-family: poppinda;
  font-size: 14px;
  color: gray;
}
.fbform-main input::placeholder {
  font-size: 14px;
  font-family: poppinda;
  color: gray;
}

@media (min-width: 768px) and (max-width: 1024px) {
  
  
  
  
  .feedbac-wrap {


    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
  }

  .fbform-main-wrap {
    /* margin: 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00536d3b;
    flex-wrap: wrap;
    height: fit-content;
    width: 100vw;
  }

  .give-us {
    width: 100%;
    color: white;
  }
  .give-us h2 {
    font-family: poppinda;
  }
  .feedback-icons {
    padding: 2px;
  }
  .feedback-iconab {
    border: 2px solid;
    display: none;
    margin: 10px ortant;
  }
  .give-us p {
    font-family: poplight;
  }
  .fbform-main {
    color: white;
    display: grid;
    justify-content: center;
    text-align: center;
    padding: 30px 30px;
    margin: 10px 5px 10px 5px !important  ;
    width: 45%;
   }
  .fbform-main p {
    display: none;
  }

  .left-of-feedback {
    width: 50%;
  }
  .fbform-main h1 {
    font-size: 20px;
    font-family: poppinsa;
  }
  .fbform-main input {
    margin: 1%;
    padding: 7px;
  }
  .fbform-main textarea {
    margin: 1%;
    padding: 7px;
  }
  .names-inp-fdbk {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .names-inp-fdbk input {
    padding: 7px;
    width: 50%;
    margin: 1%;
  }
  .fbform-main button {
    margin: 1%;
    padding: 10px;
    border: none;
    background-color: #0d77ee;
  }
  .fbform-main button:hover {
    margin: 1%;
    padding: 10px;
    border: none;
    background-color: #006894;
    color: white;
  }
}
@media (min-width:425px)and (max-width:768px) {
.feedbac-wrap {
  background-attachment: fixed;
}
  .give-us {
    width: 75%;
  }
  .fbform-main {
    margin: 20px;
  }
  .left-of-feedback {
    padding: 20px;
  }
}

@media(min-width:10px) and (max-width:425px) {
.feedbac-wrap {
  background-attachment: scroll;
}
  .fbform-main {
    padding: 50px 12px 70px 12px ;
    height: 100%;
  }
  .fbform-main p {
    /* display: none; */
    margin-bottom: 50px;


  }
  .fbform-main h1 {
    border-bottom:2px solid white ;
    /* display: none; */
    padding: 0px  30px 20px 30px ; 
    font-size: 20px !important; 
  }
  .give-us {
    display: none;
  }
  .give-us h2 {
    width: 100vw;
    /* text-align: center; */
    padding: 20px;
    /* border-bottom: 2px solid white; */
  }
  .give-us h2 {
    display: none;
  }
  .give-us p {
    display: none;
  }
}

.inp-cus-focus:focus {
  /* border :none; */
}