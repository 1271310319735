@font-face {
  font-family: poppin-bold;
  src: url(../fonts/Poppins-ExtraBold.ttf);
}
@font-face {
  font-family: poppin;
  src: url(../fonts/Poppins-Black.ttf);
}
@font-face {
  font-family: light-pop;
  src: url(../fonts/Poppins-Light.ttf);
}


.abt-head-wrapp {
  padding: 0px;
  background-color: #f0f0f0;
  background-image: url("https://www.rimage.com/japan/wp-content/uploads/sites/5/2015/12/business-team-conference-table-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  width: 100%;
  height: auto;
}
.mb40{
  margin-bottom: 0px !important;
}
.abt-jug-wrap {
  display: flex;align-items: center;
  justify-content: space-between;
}
.hero-logoB {
  width: 120px;
}
.abt-head-main {
  /* background-image: url(".//media/png\ Short\ Logo.png") ; */
background-position: center;
background-blend-mode: screen;
background-repeat: no-repeat;
background-size: contain;
/* background-position: ; */
  display: flex;
  border-bottom: 5px solid darkgray;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color:#f0f0f0e2;
  /* color: black; */
  padding: 0px 15px 15px 15px;
}
.blk {
  
  color: rgb(39, 39, 39)!important ;
  
}
.abt-head-data {
  padding: 0px 10px;
  max-width: 1000px;
  text-align: left;
  margin: 0px auto;
}
.abt-head-data button {
  border: none;
  /* border: 2px solid #0d77ee; */
  /* border: 2px solid black; */
  background-color: black;
  color: white;
  /* color: bla; */
  padding: 7px 12px;
  margin: 20px 0px;
  transition: 0.2s;
}

.abt-head-data button:hover {
  color: white;
  background-color: rgba(77, 77, 77, 0.808);
}
.abt-data-wrap {
  margin: 0px auto;
  max-width: 1200px;
  text-align: justify;
}
.abt-box h3 {
  text-align: center;
  font-family: poppin-bold;
}

/* Founder */
.co-founders {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.founder {
  text-align: center;
}
.center {
  text-align: center;
}
.founder img {
  width: 160px;
  height: 160px;
  border-radius: 180px;
}

/* Founder End */
/* Sponsors */
.sponsors {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0px 40px;
  flex-wrap: wrap;
  text-align: center;
}
.sponsors img {
  width: 190px;
  /* height: 160px; */
}

/* Sponsors End */

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.about-wrap-real {
  display: flex;
  justify-content: center;
}
.about-wrap {
  width: 100%;
  margin: 0px auto;
  max-width: 2060px !important;
}
.hero-about {
  width: 100%;
  height: 100vh;
  /* background-image: url("./media/about2.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #00158f;
  background-attachment: fixed;
  background-position: center;
}
.about-main-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* color: white; */
  margin: 0px auto;
}
.about-main {
  text-align: center;
}
.about-main p {
  margin: 0px;
  font-size: 20px;
}
.about-main h1 {
  font-family: poppin-bold;
  font-size: calc(1.675rem + 5.1vw);
  text-align: center;
}
.about-main button {
  padding: 10px 40px;
  border: none;
  font-size: 18px;
  background-color: white;
  text-align: center;
  margin: 20px 20px 20px 20px;
}

/* 
Hero 2 Start
*/
.hero-2 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 90px 30px;
  background-attachment: fixed;
  width: 100%;
}
.hero-2-left {
  width: 50%;
  text-align: center;
  /* border: 2px solid red; */
}
.manager-image {
  width: 50%;
  /* border-left: 5px solid #0d77ee; */
  box-shadow: -10px 10px 1px rgba(0, 0, 0, 0.199);
}
.hero-2-right {
  padding: 21px 50px;
  width: 50%;
}
.hero-2-right h1 {
  color: rgba(0, 0, 0, 0.692);
  width: fit-content;
  padding: 2px;
  font-size: 30px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.692);
}
.name-span {
  /* text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.205); */
  font-weight: 900;
  color: #0d77ee;
}
.hero-2-right p {
  color: rgba(0, 0, 0, 0.692);
  letter-spacing: 0.21px;
  padding: 2px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  word-spacing: 0.8px;
}
.hero-2-right button {
  padding: 5px;
  color: #0d77ee;
  border: none;
  margin-top: 5px;
  border-left: 2px solid #0d77ee;
}
/* Hero 2 Ends */

/* Visions Start */

.visions-main-wrap {
  width: 100%;
}
.visions-main {
  text-align: center;
}
.visions-main h2 {
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.692);
  font-family: poppin-bold;
}

.visions-head-p {
  padding: 0px 15px;
}
.visions-list {
  margin: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.visions-box {
  width: 26%;
  cursor: pointer;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 50px 0px;
  text-align: center;
  flex-wrap: wrap;
  transition: 0.4s;
  /* color: rgba(0, 0, 0, 0.692); */
}
.vision-icon {
  margin: auto;
  /* padding: 20px; */
  border-radius: 100px;
  color: white;
  margin-bottom: 10px;
  background-color: #0d77ee;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px;
}
.ion {
  transition: 0.4s;
}
.visions-box:hover .ion {
  fill: #0d77ee;
}
.visions-box:hover .vision-icon {
  background-color: white;
}

.visions-box:hover {
  background-color: #0d77ee;
  color: white !important;
}

.visions-box h3 {
  font-size: 20px;
  font-family: light-pop;
}
.visions-box p {
  font-family: light-pop;
  margin: 0px 0px;
}
.how-we-work-main-wrap h2 {
  padding: 0px 53px !important;
}
.hww-right {
  text-align: center;
}
.hww-left p {
  padding: 0px 20px;
}
.left {
  text-align: left;
}

.team-head h4 {
  text-align: center;
  font-size: 40px;
  font-family: popBold;
}
.founder h4 {
  font-family: light-pop;
  margin-bottom: 0px !important;
}
.founder p {
  font-family: light-pop;
  margin-top: 0px;
  border-top:'1px solid gray';
  width:fit-content;
  margin:0px auto
}

@media (min-width: 10px) and (max-width: 425px) {
  .abt-head-main {
    height: 100vh;
  }
}

@media (min-width: 10px) and (max-width: 720px) {
  /* Visions Start Here */
  
  .visions-box {
    width: 100%;
    margin: 20px 0px;
  }
}
@media (min-width: 10px) and (max-width: 768px) {
  /* Hero  */
  .hero-logoB {
    width: 90px;
  }
  /*Founder*/
  .co-founders {
    flex-wrap: wrap;
  }
  .founder {
    margin: 0px 20px;
  }
  .founder h4 {
    font-family: light-pop;
    margin-bottom: 0px;
    font-size: 16px;
  }
  .founder p {
    font-size: 12px;
    font-family: light-pop;
    margin-top: 3px !important;
  }
  .founder img {
    height: 140px;
    width: 140px;
  }
  /*Founder ends*/
  .how-we-work-main-wrap h2 {
    padding: 0px 25px !important;
    font-size: 30px !important;
  }
  .how-we-work-main {
    padding: 0px !important;
  }
  /* Team Member */
  .team-head {
    padding: 10px;
  }

  /* Team Member ends */

  /* Visions */
  .hww-right {
    display: none;
  }
  /* Visions end */

  .sponsors {
    margin: 10px 10px;
  }

  .team-head h4 {
    font-size: 40px;
    font-family: popBold;
  }

  .sponsors img {
    width: 120px;
    /* height: 160px; */
  }
  .hero-2-right {
    width: 100%;
    padding: 0px;
  }
  .hero-2-left {
    width: 100%;
  }
  .manager-image {
    width: 100%;
    margin-bottom: 15px;
    box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.336);
  }

  .hww-left {
    width: 100% !important;
  }
  .hww-left p {
    margin-top: 0px !important;
  }
  .hww-right {
    width: 100% !important;
  }

  .how-we-work-main-wrap {
    padding: 30px 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* Hero 2 */
  .manager-image {
    width: 100%;
  }
  .hero-2-right {
    margin: auto;
  }
  .hero-2-right {
    padding: 20px;
  }
  .hero-2-right p {
    color: rgba(0, 0, 0, 0.692);
    letter-spacing: 0.2px;
    padding: 2px;
    line-height: 19px;
    display: flex;
    align-items: flex-end;
    word-spacing: 0.8px;
  }
  /* Hero 2 End */
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .hero-2-left {
    width: 50%;
  }
  .manager-image {
    width: 55%;
    border-left: 5px solid #0d77ee;
  }
}

/* Codepan Io Material Css*/

/* Generic styles */
html {
  scroll-behavior: smooth;
}

.code-pan-io-a {
  background-color: #0d77ee;
  text-decoration: none;
  color: white;
  border-radius: 0.25rem;
  text-align: center;
  display: inline-block;
}
.code-pan-io-a:hover {
  text-decoration: none;
  color: #f0f0f0;
  background-color: #0d77ee;
}

/* Styles for the hero image */
.hero {
  /* Photo by mnm.all on Unsplash */
  background: url("https://www.hellgeeks.com/wp-content/uploads/2017/05/employees.jpg")
    center;
  background-size: cover;
  padding: 4rem 2rem;
  /* grid styles */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  align-items: center;
}

.hero > * {
  color: white;
}

.hero > h1 {
  font-size: 4rem;
  padding-bottom: 1rem;
}

.hero > article > p {
  font-size: 1.5rem;
  font-weight: 200;
}

.hero > article > a {
  padding: 1rem;
  margin-top: 0.75rem;
}

/* How we work start */
.how-we-work-main-wrap {
  padding: 30px;
  color: rgba(0, 0, 0, 0.692);
}
.how-we-work-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  /* align-items: center; */
  padding: 30px;
  padding-top: 0px;
}
.how-we-work-main-wrap h2 {
  padding: 0px 30px;
  padding-top: 30px;
  margin-bottom: 0px !important;
  font-size: 2.5em;
  text-align: left;
  font-family: poppinsab;
}
.hww-left {
  padding: 2px 5px;
  width: 70%;
}
.breakaway {
  color: #0d77ee;
}
.hww-right {
  padding: 10px 5px;

  width: 30%;
}
.hide {
  opacity: 0;
}
.boldisnga {
  color: #0d77ee;
  /* font-weight: 700; */
  /* font-size: 1.3rem; */
  font-family:poppin !important;
}