@font-face {
  font-family: popB;
  src: url("../fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: popBB;
  src: url("../fonts/Poppins-Black.ttf");
}

.brk-Btn {
  padding: 10px 15px;
  border: none;
  background-color: #0d77ee;
  border: 2px solid #0d77ee;
  color: white;
  transition: 0.5s;
}

.brk-Btn:hover {
  background-color: transparent;
  color: #0d77ee;
}

.brkLight-Btn {
  padding: 10px 15px;
  border: none;
  background-color: transparent;
  border: 2px solid #0d77ee;
  color: #0d77ee;
}

.brkLight-Btn:hover {
  background-color: #0d77ee;
  color: white;
  transition: 0.5s;
}

.square-main-wrap {
  max-width: 2060px;
  margin: 0px auto;
}

.square-main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.sqBox {
  padding: auto 0px;
  width: 50%;
  height: 500px;
}

.sqBox h1 {
  font-family: popBB;
  color: #0d77ee;
  word-spacing: 5px;
}

.sqBox p {
  margin: 35px 0px !important;
  max-width: 350px;
}

.square4 {
  padding: 20px 140px;
}

.square-data-2 h3 {
  font-weight: bold;
}

.square2 {
  width: 50%;
  /* background-image: url("https://imageio.forbes.com/specials-images/dam/imageserve/244449814/960x0.jpg?format=jpg&width=960"); */
  background-image: url("../medias/8673038_3948363-removebg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.teesra-gifta {
  background-image: url("../medias/delivery\ big.png");
  /* background-image: url("https://www.newgenerations.ie/wp-content/uploads/2022/04/pexels-pixabay-264787.jpg"); */
  /* background-blend-mode: multiply; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.square3 {
  width: 50%;
  /* background-image: url("https://www.mieleguide.com/wp-content/uploads/2020/01/images8960-5e20d755702b1-1024x684.jpg"); */
  background-image: url("../medias/work.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.square4 p {
  text-align: justify;
}

@media (min-width: 768px) and (max-width: 2560px) {
  .square-data-1 {
    margin-top: 50px;
  }

  .square-data {
    margin-top: 50px;
  }

  .square1 {
    padding: 20px 30px;
  }
  
  .square4 {
    padding: 100px 90px;
    margin: 0px auto !important;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .square1 h1 {
    font-size: 30px !important;
    line-height: 40px !important;
  }
  
  .square1 {
    padding: 50px 30px;
  }
  
  .square4 {
    padding: 10px 50px;
    margin: 0px auto !important;
  }
}

@media (min-width: 10px) and (max-width: 600px) {
  
  .square-data-1,
  .square-data-3 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    padding: 30px 0px;
  }
  
  .brk-Btn {
    font-size: 15px;
    padding: 4px 6px;
  }
  
  .brkLight-Btn {
    padding: 4px 6px;
    font-size: 15px;
  }
  
  .sqBox {
    width: 50%;
    height: 300px;
  }
  
  .square2 {
    background-image: url("../medias/mobView.png");
  }
  
  .teesra-gifta {
    background-image: url("../medias/deliveryman.png");

  }
  .square1 h1 {
    word-spacing: 0px;
    font-size: 20px !important;
    line-height: 30px !important;
    margin-bottom: 10px !important;
  }

  .square1 {
    padding: 0px 20px !important;
  }

  .square4 {
    padding: 20px 20px;
    margin: 0px auto !important;
  }

  .square4 h3 {
    font-family: popBB;
    text-align: center;
    font-size: 19px;
    margin-bottom: 10px !important;
  }

  .square4 {}

  .square4 p {
    text-align: center;
    font-size: 14px;
    margin: 0px 0px 15px 0px !important;
    margin-bottom: 20px !important;
  }
}

.square-data-2 {
  margin: 50px 0px;
}

@media (min-width: 10px) and (max-width: 635px) {
  .square-data-1 {
    margin: 0px 0px;
  }

  .square-data-2 {
    margin: 50px 0px;
  }

  .square-data {
    margin: 50px 0px;
  }

  .extra-p {
    display: none !important;
  }
}

@media (min-width: 1200px) and (max-width: 2560px) {
  .square-data {
    margin: 70px 50px;
  }
}