@font-face {
  font-family: popBold;
  src: url("../../fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: popNormal;
  src: url("../../fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: popLight;
  src: url("../../fonts/Poppins-Light.ttf");
}
@font-face {
  font-family: veryBold;
  src: url("../../fonts/Poppins-Black.ttf");
}
.frg-box-comp {
  text-align: center;
}
.comp-img {
  width: 180px;
  text-align: center;
  margin: 0px auto;
}

.s2nd-hero-comp {
  text-align: center;
  justify-content: center;
}
.s2nd-hero-comp h2 {
  font-family: popBold;
}
.s2nd-hero-comp h4 {
  opacity: 0.4;
  font-size: 16px;
  font-family: popNormal;
}
.s2nd-hero-comp p {
  font-family: popLight;
  max-width: 1000px;
  margin: 0px auto;
}

.comp-facility {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
  height: auto;
}

.face-img-wrap {
  height: 100px;
  opacity: 0.8;
}
.facility {
  padding: 20px;
  margin: 5px;
  width: 260px;
  height: auto;
  border-left: 1px solid rgba(0, 0, 0, 0.082);
  border-right: 1px solid rgba(0, 0, 0, 0.082);
  text-align: center;
  /* border-radius: 20px; */
  transition: 0.3s;
  cursor: pointer;
}
.facility:hover {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.233);
  background-color: #0d77ee;
  width: 290px;
  color: white;
}
.comp-facil-h1 {
  color: rgba(0, 0, 0, 0.7);

  font-size: 60px;
  font-weight: 100;
  padding: 40px;
  padding-bottom: 10px;
  text-align: center;
}
.h2-comp-2nd {
  color: rgba(0, 0, 0, 0.818);
  font-size: 50px !important;
  font-family: veryBold;
  word-spacing: 1px;
  line-height: 55px !important;
}
.p2-comp {
  font-family: popLight;
}
.pd-5 {
  padding: 0px 50px;
  /* font-family: popNormal !important; */
}
.comp-facil-p {
  padding: 20px;
  padding-top: 0px;
  width: 100%;
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
}
.facil-head-wrap {
  justify-content: center;
  display: flex;
  width: 100%;
}
.facil-head {
  padding: 30px;
}
.comp-facl-h2 {
  font-size: 25px;
}

.align-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.brk-btn {
  border: none;
  padding: 10px 20px;
  background-color: #0d77ee;
  color: white;
  margin: 20px 10px;
  transform: 0.2s;
}
.brk-btn:hover {
  border: 2px solid #0d77ee;
  padding: 8px 18px;
  background-color: #0d76eee8;
  color: white;
  margin: 20px 10px;
}

.faclit-p {
  font-size: 13px;
}
.fac-icon {
  width: 80px;
}
.centerer {
  width: 90%;
  display: flex;
  justify-content: space-around;
}
.facb {
  /* background-color: rgb(0, 125, 255, 0.4);
  color: white; */
  background-color: transparent;
  color: rgba(0, 0, 0, 0.616);
}
.facw {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.616);
}
.fclt-btn {
  width: 100%;
  border-radius: 10px;
  background-color: rgba(0, 255, 255, 0.445);
  padding: 10px;
  border: none;
  box-shadow: 2px 2px 1px black;
}

.img-wrap-comp {
  height: 220px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: end;
}
.facil_P {
  height: 150px;
}
.down-btn-comp:hover {
  color: white;
  text-decoration: none;
  background-color: #0d77ee;
}
.down-btn-comp {
  transition: 0.2s;
  background-color: #0070f0d5;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
}

@media (max-width: 500px) {
  .h2-comp-2nd {
    margin-top: 0px !important;
    font-family: popBold;
    font-size: 20px;
    font-size: 30px !important;
    line-height: 40px !important;
  }
  .pd-5 {
    padding: 20px !important;
  }
  .pd-5 h2 {
  }
}
