@font-face {
  font-family: popinsa-feature;
  src: url(../fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: popinsa-feature-light;
  src: url(../fonts/Poppins-Light.ttf);
}

.featuresWrap {
  display: flex;
  width: auto;
  justify-content: center;
  height: auto;
  /* border: 2px solid red; */
}
.features {
  max-width: 2060px;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}
.featureBox {
  text-align: center;
  color: white;
  width: 100%;
  padding: 50px 30px 50px 30px;
  height: auto;
  background-color: #13aff0;
  background-color: #00a5ec;
}
.featureBox:hover {
  background-color: #006692;
}

.featureBox h3 {
  font-family: popinsa-feature;
}
.featureBox p {
  font-family: popinsa-feature-light;
}
.featureBox:hover {
  transition: 0.9s;
  color: rgb(255, 255, 255);
}
.featHIghlightes {
  background-color: #269ed0;
  background-color: #006894;
}
.features-hr {
  display: flex;
  justify-content: center;
  width: 10%;
  margin: 0px auto;
  margin-bottom: 10px;
  margin-top: 0px;
  align-items: center;
  opacity: 1;
  text-align: center;
}
@media (min-width: 10px) and (max-width: 425px) {
  .featuresWrap {
    height: fit-content;
  }
  .features {
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .featureBox {
    padding: 25px 30px 25px 30px;
  }
}

@media (min-width: 425px) and (max-width: 768px) {

  .featureBox h3 {
    font-size: 19px;
    width: auto;
  }
  .featureBox p {
    font-size: 12px;
  }
}
