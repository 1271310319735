 /* @import url('');  */
 @font-face {
  font-family: popinsa ligt;
  src: url('../fonts//Poppins-Light.ttf');
 }
 .max-width-header {
   max-width: 2060px;
  display: flex;
  margin: 0px auto;
  justify-content: center;
  width: 400px !important;
 }
 .main-nav {
  margin: 0px auto;
  width: 100% ;
  max-width: 2060px;
 }
 .nav-Linka {
  cursor: pointer;
  font-size: 15px;
  padding: 10px;
  font-family:  popinsa ligt;
 }
 .nav-Linka:hover {
  text-decoration: none;
 }
.patti-top {
  width: 100vw;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color:rgba(0, 0, 0, 0.897);
  color: white;
  margin: auto;
  /* font-weight: 100; */
}
.patti-top p {
  font-size: 9px;
  margin: 0px ;
}
.patti-right
 {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0px 5px 0px 15px;
 }
.ica{
  font-size: 10px;
  
}
 .patti-right p
 {
  padding: 0px 7px 0px 7px;
 }

 
.main-nav {
  /* New */
  background-color: #13aff0;
  /* Similar Dark
  
  #269ed0
  
  */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1237%26quot%3b)' fill='none'%3e%3crect width='1440' height='560' x='0' y='0' fill='url(%23SvgjsLinearGradient1238)'%3e%3c/rect%3e%3cpath d='M1440 0L843.67 0L1440 184.8z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M843.67 0L1440 184.8L1440 240.09L619.78 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M619.78 0L1440 240.09L1440 327.9L521.62 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M521.62 0L1440 327.9L1440 338.27L159.3 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 560L349.32 560L0 331.36z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 331.36L349.32 560L766.1800000000001 560L0 198.17000000000002z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 198.17000000000002L766.1800000000001 560L813.5100000000001 560L0 144.33z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 144.33000000000004L813.5100000000001 560L1056.5800000000002 560L0 61.00000000000004z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1237'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='15.28%25' y1='-39.29%25' x2='84.72%25' y2='139.29%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1238'%3e%3cstop stop-color='rgba(0%2c 125%2c 255%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(0%2c 90%2c 206%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: cover;

  color: white;
}
.mainLogo {
  width: 240px;
  margin: -15px;
  padding-bottom: 2px;
 }
 .navbar-links { 
  padding: 0px 5px;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  transition: 0.4s;
  color: white;
 }
 .navbar-links:hover{
  border-color: #ffffff;
 }
 .navbar-links a {
  color: inherit;

  text-decoration: none;
 }
.nav-link {
  font-size: 15px;
  font-family: popinsa ligt;
  font-weight: 500;
  color: #ffffff;
  border-radius: 50px;
 margin: 0px 5px 0px 5px;
 
}

.left-wrap {
  border: none;
  padding: 0px;
}

/* as left side (Logo) */
.w3-animate-left {
  padding-left: 70px;
}

/* as right side */
.heasder2 {
  padding-left: 29px;
  padding-right: 100px;
  font-size: 16px;
}
.navbar-toggler {
  margin: 7px 4px 0px 0px ; 

}
.navbar-toggler-icon {
  background-image: url(./navtoggler.png) !important;
  border: none;
  width: 35px;
  height: 30px;
}
.login-form-button-custom {
  width: 400px;
}


.th-joinProposal{
  width: 300px;
}

.logout-btn{
  padding: 0px;
  height: fit-content;
  margin-top: 13px;
  border: none;
  background-color: transparent;
}

@media (min-width: 121px) and (max-width: 991px) {
 
  /* as left side (Logo) */
  .w3-animate-left {
    padding-left: 0px;
  }

  /* as right side */
  .heasder2 {
    margin: 0px auto;
    text-align: center;
    font-size: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 20px) and (max-width: 425px) {
  .patti-top {
    display: none;
  }
  .mainLogo {
    width: 170px;
    margin: -15px 0px -15px 0px;
  }
}
