.spacer {
    width:100vw;
    height:60px;
    background-color:#f0f0f0;
}




@media (min-width: 10px) and (max-width: 991px) {
    .spacer {
        width:100vw;
        height:55px;
        background-color:rgb(25, 0, 255);
    }


}
 